import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import { DropdownOption } from '../../elements/selectors/Selectors';
import { useUser } from '../../contexts/auth/User';
import { SimpleManufacturerResponse } from '../../api/petcloudapi/api';

export type ManufacturerDict = { [key: string]: SimpleManufacturerResponse };

type ManufacturerOptionsOptions = {
  noAllManufacturers?: boolean;
  presetManufacturerOptions?: DropdownOption[] | null;
};

const useManufacturerOptions = (opts?: ManufacturerOptionsOptions) => {
  const { user } = useUser();
  const api = usePetCloudApi();
  const manufacturersApi = api.manufacturersApi();
  const errorHandler = useErrorHandler();

  const [manufacturerOptions, setManufacturerOptions] = useState<
    DropdownOption[] | null | 'NO_PERMISSION'
  >(opts?.presetManufacturerOptions ?? null);

  const [manufacturerDict, setManufacturerDict] = useState<
    ManufacturerDict | null | 'NO_PERMISSION'
  >(null);

  useEffect(() => {
    if (user?.isProductOwner) {
      manufacturersApi
        .manufacturersGetSimpleManufacturers()
        .then((response) => {
          console.log(response);
          const options = response.data.map((manufacturer) => {
            return {
              id: manufacturer.id,
              name: manufacturer.companyName,
            };
          });

          // fill dictionary
          const dict: ManufacturerDict = {};
          response.data.forEach((manufacturer) => {
            dict[manufacturer.id] = manufacturer;
          });
          setManufacturerDict(dict);

          if (!opts?.presetManufacturerOptions) {
            // if (!opts?.noAllManufacturers) {
            //   options.unshift({
            //     name: t('view.products.filters.allManufacturers'),
            //     id: 'all',
            //   });
            // }
            setManufacturerOptions(options);
          }
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    } else {
      setManufacturerOptions('NO_PERMISSION');
      setManufacturerDict('NO_PERMISSION');
    }
  }, []);

  return {
    manufacturerOptions,
    manufacturerDict,
  };
};

export default useManufacturerOptions;
