import './newreturn.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import {
  CreateOrderReturnRequest,
  OrderPositionResponse,
  OrderResponse,
  ProductStockQuality,
} from '../../../../api/petcloudapi/api';
import Button from '../../../../elements/button/Button';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import PositionEntry from '../../../../types/PositionEntry';
import TranslatedStringIndex from '../../../../types/TranslatedStringIndex';
import { getOrderPositionEntries } from '../../positions/Positions';
import ReturnPositionsEdit from '../returnpositionsedit/ReturnPositionsEdit';
import List from '../../../../features/list/List';

interface NewReturnProps {
  order: OrderResponse;
  orderPositions: OrderPositionResponse[];
  closePopup: () => void;
  getOrder: () => void;
}

const NewReturn: React.FC<NewReturnProps> = ({
  order,
  orderPositions,
  closePopup,
  getOrder,
}) => {
  const { t, i18n } = useTranslation();
  const api = usePetCloudApi();
  const orderReturnsApi = api.orderReturnsApi();
  const errorHandler = useErrorHandler();
  const [positions, setPositions] = useState<PositionEntry[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<PositionEntry[]>(
    []
  );
  const [step, setStep] = useState<'selection' | 'finalization'>('selection');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const selectAllPositions = () => {
    setSelectedPositions(positions);
  };

  const selectPosition = (position: PositionEntry) => {
    const selected = [...selectedPositions];
    const i = selected.findIndex((p) => p.id === position.id);
    if (i !== -1) {
      selected.splice(i, 1);
    } else {
      selected.push(position);
    }
    setSelectedPositions([...selected]);
  };

  useEffect(() => {
    setPositions(
      getOrderPositionEntries(
        orderPositions,
        i18n.language as TranslatedStringIndex,
        order.currency?.symbol,
        true
      )
    );
  }, []);

  const submit = () => {
    setIsSubmitting(true);
    const payload: CreateOrderReturnRequest = {
      orderId: order.id,
      orderReturnPositions: selectedPositions.map((position) => {
        return {
          quantity: position.quantity,
          quality:
            position.quality !== ('Normal' as ProductStockQuality)
              ? position.quality
              : undefined,
          orderPositionId: position.positionId,
          batchNumber: position.batchNumber,
          bestBefore: position.bestBefore,
          logisticsReturnReason: position.logisticsReturnReason,
          notes: position.notes,
          returnDate: position.returnDate,
        };
      }),
    };
    orderReturnsApi
      .orderReturnsCreateOrderReturn(payload)
      .then((response) => {
        console.log(response);
        Store.addNotification({
          message: t('view.order.returns.notifications.create_successful'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
        setSelectedPositions([]);
        setIsSubmitting(false);
        setStep('selection');
        closePopup();
        getOrder();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  if (positions.length > 0) {
    return (
      <div className="newreturn">
        {step === 'selection' ? (
          <>
            <div className="newreturn-text">
              {t('view.order.returns.new.selectPositionsPrompt')}
            </div>
            <List
              items={positions}
              selectedItems={selectedPositions}
              onSelect={selectPosition}
              onSelectAll={selectAllPositions}
              ignore={[
                'id',
                'productId',
                'positionId',
                'refundAmountCurrency',
                'batchNumber',
                'bestBefore',
                'quantity',
                'notes',
                'logisticsReturnReason',
                'quality',
                'returnDate',
                'currency',
                'isVariant',
              ]}
              itemImgKey={'coverUri'}
              monoSpaceStrings={['productNumber', 'ean']}
              priceKeysConfig={{
                priceKeys: ['price'],
                currencySymbol: order.currency?.symbol,
              }}
            />
            <div className="newreturn-actions">
              <Button
                cta={t('actions.next')}
                look={'secondary'}
                width="minimal"
                action={() => setStep('finalization')}
                active={selectedPositions.length > 0}
              />
            </div>
          </>
        ) : null}
        {step === 'finalization' && selectedPositions.length > 0 ? (
          <>
            <div className="newreturn-text">
              {t('view.order.returns.new.finalizePositionsPrompt')}
            </div>
            <ReturnPositionsEdit
              returnPositions={selectedPositions}
              updatePositions={(updatedPositions) =>
                setSelectedPositions(updatedPositions)
              }
            />
            <div className="newreturn-actions-two">
              <Button
                cta={t('actions.back')}
                look={'secondary'}
                width="minimal"
                action={() => setStep('selection')}
              />
              <Button
                cta={t('view.order.returns.new.submit')}
                look="save"
                width="minimal"
                action={submit}
                isLoading={isSubmitting}
              />
            </div>
          </>
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};

export default NewReturn;
