import './selectors.css';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckMark } from '../../../assets/icon/check.svg';
import { ReactComponent as HalfCheckMark } from '../../../assets/icon/check_half.svg';
import { ReactComponent as Arrow } from '../../../assets/icon/arrow_dropdown.svg';
import { TranslatedTypeResponseOfString } from '../../api/petcloudapi/api';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import _ from 'lodash';
import InputHeader from '../input/inputheader/InputHeader';

export type DropdownOption = {
  id: string;
  name: string;
};

interface DropdownProps {
  options?: string[] | null;
  optionObjects?: DropdownOption[] | null;
  translatableOptionObjects?: {
    id: string;
    name: TranslatedTypeResponseOfString;
  }[];
  className?: string;
  update?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  title?: string;
  hint?: {
    title?: string;
    paragraphs?: string[];
  };
  selected?: string | null;
  inherited?: string | null;
  translationPath?: string;
  translationPathSuffix?: string;
  defaultOptionText?: string;
  required?: boolean;
  disabled?: boolean;
  errors?: string[] | null;
  inputEmbedded?: boolean;
  locked?: boolean;
  height?: number;
  widthAuto?: boolean;
  disableAutoOptionSorting?: boolean;
}

export const Dropdown: React.FC<DropdownProps> = ({
  options,
  optionObjects,
  translatableOptionObjects,
  className,
  update,
  title,
  hint,
  selected,
  inherited,
  translationPath,
  translationPathSuffix,
  defaultOptionText,
  required,
  disabled,
  errors,
  inputEmbedded,
  locked,
  height,
  widthAuto,
  disableAutoOptionSorting,
}) => {
  const { t, i18n } = useTranslation();
  let c = `dropdown
  ${className ? className : ''}
  ${(required && !selected) || errors ? ' dropdown-alert' : ''}
  ${disabled ? ' dropdown-disabled' : ''}
  ${inherited && !selected ? ' dropdown-inherited' : ''}
  ${inputEmbedded ? ' dropdown-inputEmbedded' : ''}`;

  if (options && !disableAutoOptionSorting) {
    options.sort();
  } else if (optionObjects && !disableAutoOptionSorting) {
    optionObjects = _.sortBy(optionObjects, ['name']);
  }

  return (
    <div className="dropdown-wrapper">
      {title ? (
        <InputHeader
          title={title}
          inherited={!!inherited}
          brokenInheritance={!!selected}
          locked={locked}
          required={required}
          hint={hint}
        />
      ) : null}
      <div
        className="dropdown-container"
        style={widthAuto ? { display: 'inline-block' } : undefined}
      >
        <select
          className={c}
          onClick={(e) => e.stopPropagation()}
          onChange={disabled ? undefined : update}
          value={selected ? selected : inherited ?? 'default'}
          disabled={disabled}
          style={
            widthAuto
              ? {
                  width: 'auto',
                  height: height ?? 40,
                }
              : { height: height ?? 40 }
          }
        >
          {selected ? null : (
            <option className="dropdown-option" value={'default'}>
              {defaultOptionText ? defaultOptionText : t('status.select')}
            </option>
          )}
          {options
            ? options.map((option, i) => {
                if (translationPath && !translationPathSuffix) {
                  return (
                    <option
                      key={i}
                      className="dropdown-option"
                      value={option}
                      data-value={option}
                    >
                      {t(translationPath + option)}
                    </option>
                  );
                } else if (translationPath && translationPathSuffix) {
                  return (
                    <option
                      key={i}
                      className="dropdown-option"
                      value={option}
                      data-value={option}
                    >
                      {t(translationPath + option + translationPathSuffix)}
                    </option>
                  );
                } else {
                  return (
                    <option
                      key={i}
                      className="dropdown-option"
                      value={option}
                      data-value={option}
                    >
                      {option}
                    </option>
                  );
                }
              })
            : null}
          {optionObjects
            ? optionObjects.map((optionObject, i) => {
                if (translationPath && !translationPathSuffix) {
                  return (
                    <option
                      key={i}
                      className="dropdown-option"
                      value={optionObject.name}
                      data-value={optionObject.id}
                    >
                      {t(translationPath + optionObject.name)}
                    </option>
                  );
                } else if (translationPath && translationPathSuffix) {
                  return (
                    <option
                      key={i}
                      className="dropdown-option"
                      value={optionObject.name}
                      data-value={optionObject.id}
                    >
                      {t(
                        translationPath +
                          optionObject.name +
                          translationPathSuffix
                      )}
                    </option>
                  );
                } else {
                  return (
                    <option
                      key={i}
                      className="dropdown-option"
                      value={optionObject.name}
                      data-value={optionObject.id}
                    >
                      {optionObject.name}
                    </option>
                  );
                }
              })
            : null}
          {translatableOptionObjects
            ? translatableOptionObjects.map((translatableOptionObject, i) => {
                const value =
                  translatableOptionObject.name[
                    i18n.language as TranslatedStringIndex
                  ];
                if (value) {
                  return (
                    <option
                      key={i}
                      className="dropdown-option"
                      value={value}
                      data-value={translatableOptionObject.id}
                    >
                      {value}
                    </option>
                  );
                } else {
                  return null;
                }
              })
            : null}
        </select>
        <Arrow fill={'var(--color-text_primary)'} className="dropdown-arrow" />
      </div>
      {errors
        ? errors.map((error, i) => {
            return (
              <div key={i} className="input-field-error">
                {error}
              </div>
            );
          })
        : null}
    </div>
  );
};

export interface CheckProps {
  checked: boolean;
  halfChecked?: boolean;
  update?: () => void;
  inherited?: boolean;
  title?: string;
  text?: string;
  disabled?: boolean;
  hint?: string;
  selectedClassOverride?: string;
  textColor?: string;
  helperCSSClass?: string;
}

export const Check: React.FC<CheckProps> = ({
  checked,
  halfChecked,
  update,
  inherited,
  title,
  text,
  disabled,
  hint,
  selectedClassOverride,
  textColor,
  helperCSSClass,
}) => {
  return (
    <div className={`check ${helperCSSClass ?? ''}`}>
      {title ? (
        <InputHeader
          title={title}
          inherited={inherited !== undefined}
          brokenInheritance={!inherited}
          hint={hint ? { paragraphs: [hint] } : undefined}
        />
      ) : null}
      <div className="check-body">
        {checked || halfChecked ? (
          <div
            className={
              inherited
                ? 'global-selector-selected global-selector-selected-inherited'
                : disabled
                ? 'global-selector-selected-disabled'
                : selectedClassOverride ?? 'global-selector-selected'
            }
            onClick={
              !disabled
                ? (e) => {
                    if (update) {
                      e.stopPropagation();
                      update();
                    }
                  }
                : undefined
            }
          >
            {checked ? (
              <CheckMark
                className="global-selector-selected-img"
                fill={disabled ? 'var(--color-gray)' : 'white'}
              />
            ) : (
              <HalfCheckMark
                className="global-selector-selected-img"
                fill={disabled ? 'var(--color-gray)' : 'white'}
              />
            )}
          </div>
        ) : (
          <div
            className="global-selector-unselected"
            style={{
              backgroundColor: disabled
                ? 'var(--color-border)'
                : 'var(--color-input_background)',
            }}
            onClick={
              !disabled
                ? (e) => {
                    if (update) {
                      e.stopPropagation();
                      update();
                    }
                  }
                : undefined
            }
          ></div>
        )}
        {text ? (
          <div
            className="check-text"
            style={textColor ? { color: textColor } : undefined}
          >
            {text}
          </div>
        ) : null}
      </div>
    </div>
  );
};

// <div className="dropdown-title-wrapper">
//   {inherited !== undefined ? (
//     inherited ? (
//       <Linked
//         className="global-icon-inherited"
//         fill="var(--color-inherited)"
//       />
//     ) : (
//       <Unlinked
//         className="global-icon-inherited"
//         fill="var(--color-text_tertiary)"
//       />
//     )
//   ) : null}
//   <div
//     className={
//       inherited ? 'dropdown-title-inherited' : 'dropdown-title'
//     }
//   >
//     {title}
//   </div>
//   {hint ? <SmallHint paragraphs={[hint]} /> : null}
// </div>

interface TabSelectorProps {
  tabs: { title: string; key: string }[];
  activeTabKey: string;
  update: (key: string) => void;
  look?: 'bare' | 'card' | 'spanToEdges';
}

export const TabSelector: React.FC<TabSelectorProps> = ({
  tabs,
  activeTabKey,
  update,
  look,
}) => {
  return (
    <div
      className={
        look === 'card'
          ? 'tabselectorCard'
          : look === 'spanToEdges'
          ? 'tabselector tabselector-spanToEdges'
          : 'tabselector'
      }
    >
      {tabs.map((tab, i) => {
        return (
          <div
            key={tab.toString() + i}
            className={
              'tabselector-tab ' +
              (activeTabKey === tab.key ? 'tabselector-tab-active' : null)
            }
            onClick={() => update(tab.key)}
          >
            {tab.title}
          </div>
        );
      })}
    </div>
  );
};
