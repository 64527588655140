import './brandcontactaddress.css';
import { UpdateBrandContactAddressRequest } from '../../../../api/petcloudapi/api';
import { useState } from 'react';
import FormBuilder from '../../../../elements/formbuilder/FormBuilder';
import { useTranslation } from 'react-i18next';
import { DropdownOption } from '../../../../elements/selectors/Selectors';
import useCountryOptions from '../../../../hooks/data/useCountryOptions';
import Button from '../../../../elements/button/Button';
import Retractable from '../../../../elements/retractable/Retractable';
import { ReactComponent as IconTrash } from '../../../../../assets/icon/trash.svg';

interface BrandContactAddressProps {
  currentAddress: UpdateBrandContactAddressRequest;
  onSubmit: (address: UpdateBrandContactAddressRequest) => void;
  onDelete?: () => void;
  isSubmitting: boolean;
  isRetractable?: boolean;
}

const BrandContactAddress: React.FC<BrandContactAddressProps> = ({
  currentAddress,
  onSubmit,
  onDelete,
  isSubmitting,
  isRetractable,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.brand.contactAddresses.address',
  });
  const [address, setAddress] =
    useState<UpdateBrandContactAddressRequest>(currentAddress);
  const { countryOptions } = useCountryOptions();

  const departmentOptions: DropdownOption[] = [
    {
      id: 'ManagingDirector',
      name: t('departmentOptions.managingDirector'),
    },
    {
      id: 'Accounting',
      name: t('departmentOptions.accounting'),
    },
    {
      id: 'Sales',
      name: t('departmentOptions.sales'),
    },
    {
      id: 'ContentDirector',
      name: t('departmentOptions.contentDirector'),
    },
    {
      id: 'CustomerSupport',
      name: t('departmentOptions.customerSupport'),
    },
    {
      id: 'ProductPreparation',
      name: t('departmentOptions.productPreparation'),
    },
    {
      id: 'ProductManagement',
      name: t('departmentOptions.productManagement'),
    },
    {
      id: 'ProductDamageSettlement',
      name: t('departmentOptions.productDamageSettlement'),
    },
    {
      id: 'ProductManufacturer',
      name: t('departmentOptions.productManufacturer'),
    },
  ];

  const salutationOptions: string[] = [
    t('salutationOptions.mr'),
    t('salutationOptions.mrs'),
    t('salutationOptions.diverse'),
  ];

  const renderHead = () => {
    return (
      <div className={'brandContactAddress-head'}>
        <div className={'brandContactAddress-head-meta'}>
          <div className={'brandContactAddress-head-meta-title'}>
            {departmentOptions.find((x) => x.id === address.department)?.name}
          </div>
          <div className={'brandContactAddress-head-meta-item'}>
            {address.companyName}
          </div>
        </div>
        {onDelete ? (
          <Button
            type={'icon'}
            width={'minimal'}
            look={'secondary'}
            action={onDelete}
          >
            <IconTrash className={'button-icon button-icon-danger'} />
          </Button>
        ) : null}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        <FormBuilder
          contentObject={address}
          setContentObject={setAddress}
          elements={[
            {
              type: 'input',
              title: t('companyName'),
              objectPath: 'companyName',
              required: true,
            },
            {
              type: 'group',
              children: [
                {
                  type: 'input',
                  title: t('street'),
                  objectPath: 'street',
                },
                {
                  type: 'input',
                  title: t('streetNumber'),
                  objectPath: 'streetNumber',
                },
              ],
            },
            {
              type: 'group',
              children: [
                {
                  type: 'input',
                  title: t('zipCode'),
                  objectPath: 'zipCode',
                  required: true,
                },
                {
                  type: 'input',
                  title: t('city'),
                  objectPath: 'city',
                  required: true,
                },
              ],
            },
            {
              type: 'group',
              children: [
                {
                  type: 'input',
                  title: t('state'),
                  objectPath: 'state',
                },
                {
                  type: 'dropdown',
                  title: t('country'),
                  optionObjects: countryOptions,
                  objectPath: 'countryId',
                },
              ],
            },
            {
              type: 'group',
              children: [
                {
                  type: 'input',
                  title: t('additionalAddressLine1'),
                  objectPath: 'additionalAddressLine1',
                },
                {
                  type: 'input',
                  title: t('additionalAddressLine2'),
                  objectPath: 'additionalAddressLine2',
                },
              ],
            },
            {
              type: 'dropdown',
              title: t('department'),
              optionObjects: departmentOptions,
              objectPath: 'department',
              required: true,
            },
            {
              type: 'group',
              children: [
                {
                  type: 'dropdown',
                  title: t('salutation'),
                  objectPath: 'salutation',
                  options: salutationOptions,
                },
                {
                  type: 'input',
                  title: t('firstName'),
                  objectPath: 'firstName',
                },
                {
                  type: 'input',
                  title: t('lastName'),
                  objectPath: 'lastName',
                },
              ],
            },
            {
              type: 'input',
              title: t('email'),
              objectPath: 'email',
            },
          ]}
        />
        <div className={'global-cardActions-margin'}>
          <Button
            cta={t('cta')}
            width={'minimal'}
            look={'save'}
            action={() => onSubmit(address)}
            isLoading={isSubmitting}
          />
        </div>
      </>
    );
  };

  return (
    <div className={'brandContactAddress'}>
      {isRetractable ? (
        <Retractable head={renderHead()}>
          <div className={'brandContactAddress-content'}>{renderContent()}</div>
        </Retractable>
      ) : (
        renderContent()
      )}
    </div>
  );
};

export default BrandContactAddress;
