import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import { DropdownOption } from '../../elements/selectors/Selectors';
import { ProductGroupResponse } from '../../api/petcloudapi/api';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';

export type GroupDict = { [key: string]: ProductGroupResponse };

type ProductGroupOptionsOptions = {
  presetProductGroupOptions?: DropdownOption[] | null;
};

const useProductGroupOptions = (opts?: ProductGroupOptionsOptions) => {
  const { i18n } = useTranslation();
  const api = usePetCloudApi();
  const productGroupsApi = api.productGroupsApi();
  const errorHandler = useErrorHandler();

  const [productGroupOptions, setProductGroupOptions] = useState<
    DropdownOption[] | null
  >(opts?.presetProductGroupOptions ?? null);

  const [productGroupDict, setProductGroupDict] = useState<GroupDict | null>(
    null
  );

  useEffect(() => {
    productGroupsApi
      .productGroupsGetProductGroups()
      .then((response) => {
        console.log(response);
        const options = response.data.map((group) => {
          return {
            id: group.id,
            name:
              group.name[i18n.language as TranslatedStringIndex] ??
              'productGroup translation missing',
          };
        });

        // fill dictionary
        const dict: GroupDict = {};
        response.data.forEach((group) => {
          dict[group.id] = group;
        });
        setProductGroupDict(dict);

        if (!opts?.presetProductGroupOptions) {
          setProductGroupOptions(options);
        }
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  }, []);

  return {
    productGroupOptions,
    productGroupDict,
  };
};
export default useProductGroupOptions;
