import { useState } from 'react';
import { ListFilterProps } from '../listfilter/ListFilter';
import { useTranslation } from 'react-i18next';
import useUrlParams from '../../../../hooks/useUrlParams';
import useLocalStorageSettings from '../../../../hooks/useLocalStorageSettings';

type LimitListFilterOptions = {
  localStorageLimitSettingPrefix?: string;
  defaultLimit?: number;
};

const useLimitListFilter = (opts?: LimitListFilterOptions) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.useListFilters',
  });
  const { setUrlParam, getUrlParam } = useUrlParams();
  const { setSetting, getSetting } = useLocalStorageSettings();

  const getLimitFromURLParams = () => {
    const l = getUrlParam('limit');
    if (l) {
      if (l !== 'none') {
        return parseInt(l);
      } else {
        return undefined;
      }
    }
  };

  const getDefaultLimit = () => {
    if (opts?.defaultLimit) {
      return opts.defaultLimit;
    } else {
      return 250;
    }
  };

  const getInitialLimit = () => {
    const urlLimit = getLimitFromURLParams();
    if (urlLimit) {
      return urlLimit;
    } else {
      if (opts?.localStorageLimitSettingPrefix) {
        const setting = getSetting(
          `${opts?.localStorageLimitSettingPrefix}_limit`
        );

        if (setting) {
          if (setting === 'noLimit') {
            return undefined;
          } else {
            return parseInt(setting);
          }
        } else {
          return getDefaultLimit();
        }
      } else {
        return getDefaultLimit();
      }
    }
  };

  const [selectedLimit, setSelectedLimit] = useState<number | undefined>(
    getInitialLimit()
  );

  const limitFilter: ListFilterProps = {
    optionObjects: [
      { name: '250', id: '250' },
      { name: '500', id: '500' },
      { name: '1000', id: '1000' },
      { name: '2000', id: '2000' },
      { name: '4000', id: '4000' },
      {
        name: t('noLimit'),
        id: 'noLimit',
      },
    ],
    selected: selectedLimit ? selectedLimit.toString() : t('noLimit'),
    update: (option) => {
      const l = option.id;
      if (l && l !== 'noLimit' && selectedLimit !== parseInt(l)) {
        setSelectedLimit(parseInt(l));
        setUrlParam('limit', l);
        if (opts?.localStorageLimitSettingPrefix) {
          setSetting(`${opts?.localStorageLimitSettingPrefix}_limit`, l);
        }
      } else {
        setSelectedLimit(undefined);
        setUrlParam('limit', 'none');
        if (opts?.localStorageLimitSettingPrefix)
          setSetting(
            `${opts?.localStorageLimitSettingPrefix}_limit`,
            'noLimit'
          );
      }
    },
    disableAutoOptionSorting: true,
    nativeDropdown: true,
  };

  return {
    selectedLimit,
    limitFilter,
  };
};

export default useLimitListFilter;
