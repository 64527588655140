import { ManufacturerConditionResponse } from '../../api/petcloudapi/api';
import { useUser } from '../../contexts/auth/User';
import { useEffect, useState } from 'react';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';

const useManufacturerConditions = () => {
  const api = usePetCloudApi();
  const manufacturerConditionsApi = api.manufacturerConditionsApi();
  const { user } = useUser();
  const errorHandler = useErrorHandler();

  const [manufacturerConditions, setManufacturerConditions] = useState<
    ManufacturerConditionResponse[] | null
  >(null);
  const [ready, setReady] = useState(false);

  // if the user is PO the conditions for a manufacturer can't be extracted from the user object
  // in this case an extra api call retrieves the information
  useEffect(() => {
    if (user?.isProductOwner) {
      getConditions();
    } else {
      setReady(true);
    }
  }, [user]);

  const getConditions = () => {
    manufacturerConditionsApi
      .manufacturerConditionsGetManufacturerConditions()
      .then((response) => {
        console.log(response);
        setManufacturerConditions(response.data);
        setReady(true);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.repsonse);
        setReady(true);
      });
  };

  const getConditionValue = (
    conditionName: string,
    manufacturerId?: string
  ) => {
    return !user?.isProductOwner
      ? user?.manufacturerConditions?.find(
          (condition) => condition.key === conditionName
        )?.value
      : manufacturerConditions && manufacturerId
      ? manufacturerConditions.find(
          (x) => x.manufacturerId === manufacturerId && x.key === conditionName
        )?.value
      : false;
  };

  const isUsingCentralWarehouse = (manufacturerId?: string) => {
    const warehousingType = getConditionValue('WarehouseUsage', manufacturerId);
    if (warehousingType === 'Central' || warehousingType === 'Hybrid') {
      return true;
    } else if (user?.isProductOwner) {
      return 'PO_OVERRIDE';
    } else {
      return false;
    }
  };

  const isUsingManufacturerWarehouse = (manufacturerId?: string) => {
    const warehousingType = getConditionValue('WarehouseUsage', manufacturerId);
    if (warehousingType === 'Custom' || warehousingType === 'Hybrid') {
      return true;
    } else if (user?.isProductOwner) {
      return 'PO_OVERRIDE';
    } else {
      return false;
    }
  };

  const isNotUsingCentralWarehouse = (manufacturerId?: string) => {
    const warehousingType = getConditionValue('WarehouseUsage', manufacturerId);
    if (warehousingType === 'Custom') {
      return true;
    } else if (user?.isProductOwner) {
      return 'PO_OVERRIDE';
    } else {
      return false;
    }
  };

  const isAware = (conditionName: string, manufacturerId?: string) => {
    const isAware = getConditionValue(conditionName, manufacturerId);
    if (isAware === 'true') {
      return true;
    } else {
      if (user?.isProductOwner) {
        return 'PO_OVERRIDE';
      } else {
        return false;
      }
    }
  };

  const isOrderReturnAnnouncementAware = (manufacturerId?: string) => {
    return isAware('OrderReturnAnnouncementAware', manufacturerId);
  };

  const isPromotionAware = (manufacturerId?: string) => {
    return isAware('PromotionAware', manufacturerId);
  };

  const isProductBundleAware = (manufacturerId?: string) => {
    return isAware('ProductBundleAware', manufacturerId);
  };

  return {
    isUsingCentralWarehouse,
    isUsingManufacturerWarehouse,
    isNotUsingCentralWarehouse,
    isOrderReturnAnnouncementAware,
    isPromotionAware,
    isProductBundleAware,
    ready,
  };
};

export default useManufacturerConditions;
