import './fulleditor.css';
import { useState, useEffect, useRef, useCallback } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce/tinymce';
import _ from 'lodash';
import { useDarkModeDetector } from '../themeswitcher/ThemeSwitcher';
import Popup from '../popup/Popup';
import FileBrowser from '../filebrowser/FileBrowser';
import { LoadingContainer } from '../loading/Loading';
import { AssetFolderResponse, AssetResponse } from '../../api/petcloudapi/api';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import InputHeader from '../input/inputheader/InputHeader';

interface FullEditorProps {
  title?: string;
  required?: boolean;
  onUpdate: (content: string) => void;
  initialHTMLValue?: string | null;
  inherited?: string | null;
  errors?: string[] | null;
  fixedToolbarContainer?: string;
}

const FullEditor: React.FC<FullEditorProps> = ({
  title,
  required,
  initialHTMLValue,
  inherited,
  onUpdate,
  errors,
  fixedToolbarContainer,
}) => {
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const api = usePetCloudApi();
  const assetFoldersApi = api.assetFoldersApi();
  const errorHandler = useErrorHandler();
  const [initialValue, setInitialValue] = useState<string | null>(null);
  const [editorIsReady, setEditorIsReady] = useState(false);
  const darkmode = useDarkModeDetector();
  const [currentContent, setCurrentContent] = useState<
    string | null | undefined
  >(null);
  const [fileBrowserPopup, setFileBrowserPopup] = useState(false);
  const [assetFolders, setAssetFolders] = useState<
    AssetFolderResponse[] | null
  >(null);

  useEffect(() => {
    setInitialValue(initialHTMLValue ?? null);

    //tinymce.PluginManager.add('imageBrowser', () => {});
  }, []);

  useEffect(() => {
    if (currentContent !== null && currentContent !== undefined) {
      onUpdate(currentContent);
    }
  }, [currentContent]);

  const debouncedUpdate = useCallback(
    _.debounce(() => {
      setCurrentContent(editorRef.current?.getContent());
    }, 500),
    []
  );

  const inheritanceBroken = !!(
    initialHTMLValue && inherited !== initialHTMLValue
  );

  useEffect(() => {
    if (fileBrowserPopup) {
      assetFoldersApi
        .assetFoldersGetAssetFolders()
        .then((response) => {
          console.log(response);
          setAssetFolders(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  }, [fileBrowserPopup]);

  const setup = useCallback((editor: TinyMCEEditor) => {
    editor.ui.registry.addButton('imageBrowser', {
      icon: 'gallery',
      onAction: () => {
        setFileBrowserPopup(true);
      },
    });
  }, []);

  const insertImages = (assets: AssetResponse[]) => {
    assets.forEach((asset) => {
      editorRef?.current?.insertContent(`<img src='${asset.uri}'/>`);
    });
    setFileBrowserPopup(false);
  };

  return (
    <div className="fulleditor">
      {title ? (
        <InputHeader
          title={title}
          required={required}
          inherited={!!inherited}
          brokenInheritance={inheritanceBroken}
        />
      ) : null}
      <div
        className={
          errors
            ? 'fulleditor-error'
            : inherited && !inheritanceBroken
            ? 'fulleditor-inherited'
            : ''
        }
      >
        {/* @ts-ignore*/}
        <Editor
          init={{
            content_style: `
              body { 
                font-family: 'Sofia Pro', sans-serif; 
                ${darkmode() ? 'color: #e8e8e8;' : 'color: black;'}
                font-size: 16px;
                line-height: 22px;
                -webkit-font-smoothing: antialiased
              }`,
            entity_encoding: 'raw',
            plugins: ['image'],
            toolbar:
              'undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | imageBrowser',
            setup,
            fixed_toolbar_container: fixedToolbarContainer
              ? `#${fixedToolbarContainer}`
              : undefined,
          }}
          tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
          onInit={(e, editor) => {
            editorRef.current = editor;
            setEditorIsReady(true);
          }}
          plugins={'code'}
          initialValue={
            initialValue ? initialValue : inherited ? inherited : ''
          }
          onEditorChange={() => {
            if (editorIsReady) {
              debouncedUpdate();
            }
          }}
        />
      </div>
      {errors ? (
        <div className={'input-field-error-msgs'}>
          {errors.map((error, i) => {
            return (
              <div key={i} className="input-field-error">
                {error}
              </div>
            );
          })}
        </div>
      ) : null}
      <Popup
        toggled={fileBrowserPopup}
        width={'60%'}
        close={() => setFileBrowserPopup(false)}
      >
        {assetFolders ? (
          <FileBrowser
            assetFolders={assetFolders}
            allowUrlNavigation={false}
            onChooseAssets={insertImages}
          />
        ) : (
          <LoadingContainer />
        )}
      </Popup>
    </div>
  );

  // return (
  //   <div className="fulleditor">
  //     {title ? <div className="fulleditor-title">{title}</div> : null}
  //     <div className={errors ? 'fulleditor-error' : ''}>
  //       {htmlEditor ? (
  //         <HtmlEditor
  //           inherited={inherited}
  //           width={width}
  //           height={height}
  //           switchToSlate={() => setHtmlEditor(false)}
  //           content={htmlContent}
  //           updateContent={(content) => {
  //             setHtmlContent(content);
  //             if (onUpdate) {
  //               onUpdate(content);
  //             }
  //           }}
  //         />
  //       ) : (
  //         <RichTextEditor
  //           inherited={inherited}
  //           width={width}
  //           height={height}
  //           initialValue={getSlateValue(initialHTMLValue)}
  //           switchToHTML={() => setHtmlEditor(true)}
  //           onChange={onChange}
  //         />
  //       )}
  //     </div>
  //     {errors
  //       ? errors.map((error, i) => {
  //           return (
  //             <div key={i} className="input-field-error">
  //               {error}
  //             </div>
  //           );
  //         })
  //       : null}
  //   </div>
  // );
};

export default FullEditor;
