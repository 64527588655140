import './translatedrichtext.css';
import { TranslatedTypeResponseOfString } from '../../../../api/petcloudapi/api';
import { memo, useState } from 'react';
import {
  EditorActions,
  EditorUpdateCallback,
} from '../../../../features/jsontable/JsonTable';
import FullEditor from '../../../../elements/fulleditor/FullEditor';
import { Tab, Tabs } from '../../../../elements/card/Card';
import useSupportedLangs from '../../../../hooks/useSupportedLangs';

interface TranslatedRichTextProps {
  value: TranslatedTypeResponseOfString;
  updateCallback: EditorUpdateCallback;
}

const TranslatedRichTextEditor: React.FC<TranslatedRichTextProps> = ({
  value,
  updateCallback,
}) => {
  const supportedLangs = useSupportedLangs();

  const getDefaultValue = () => {
    const obj: TranslatedTypeResponseOfString = {};
    supportedLangs.forEach((lang) => {
      obj[lang] = null;
    });
    return obj;
  };

  const [translation, setTranslation] = useState(value ?? getDefaultValue());

  const [tabs] = useState(
    Object.keys(translation).map((key) => {
      return {
        title: key,
        key: key,
      };
    })
  );

  return (
    <div className={'jtce-richText'}>
      <div className={'jtce-richText-tabs'}>
        <Tabs tabs={tabs} look={'bare'} dontSetHash>
          {Object.entries(translation).map(([key, value]) => {
            return (
              <Tab key={key}>
                <div
                  id={'jtce-richText-input'}
                  className={'jtce-richText-input'}
                >
                  <FullEditor
                    initialHTMLValue={value}
                    onUpdate={(c) => {
                      setTranslation({
                        ...translation,
                        [key]: c,
                      });
                    }}
                    fixedToolbarContainer={'jtce-richText-input'}
                  />
                </div>
              </Tab>
            );
          })}
        </Tabs>
      </div>
      {updateCallback ? (
        <EditorActions save={() => updateCallback([{ value: translation }])} />
      ) : null}
    </div>
  );
};

export default memo(TranslatedRichTextEditor);
