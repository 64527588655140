import { useMemo, useState } from 'react';
import { ListFilterProps } from '../listfilter/ListFilter';
import { useTranslation } from 'react-i18next';
import useUrlParams from '../../../../hooks/useUrlParams';
import { DropdownOption } from '../../../../elements/selectors/Selectors';
import useBrandOptions from '../../../../hooks/data/useBrandOptions';

type BrandListFilterOptions = {
  noAllBrands?: boolean;
  presetBrandOptions?: DropdownOption[] | null;
};

const useBrandListFilter = (opts?: BrandListFilterOptions) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.useListFilters',
  });
  const { setUrlParam, removeUrlParam, getUrlParam } = useUrlParams();
  const { brandOptions } = useBrandOptions({
    noAllBrands: opts?.noAllBrands,
    presetBrandOptions: opts?.presetBrandOptions,
  });

  const getBrandsFromURLParams = () => {
    const brandsParam = getUrlParam('brandIds');
    if (brandsParam && brandsParam !== '') {
      const result = brandsParam.split('_');
      result.pop();
      return result;
    } else {
      return null;
    }
  };

  const [selectedBrandIds, setSelectedBrandIds] = useState<string[] | null>(
    getBrandsFromURLParams()
  );

  const brandsFilter: ListFilterProps | undefined = useMemo(() => {
    const options = opts?.presetBrandOptions ?? brandOptions;

    const selected = opts?.presetBrandOptions
      ? opts?.presetBrandOptions
          .filter((x) => selectedBrandIds?.includes(x.id))
          .map((x) => x.name)
      : brandOptions
      ? brandOptions
          .filter((x) => selectedBrandIds?.includes(x.id))
          .map((x) => x.name)
      : [];

    return options
      ? {
          optionObjects: options,
          multiSelected: selected,
          update: (option) => {
            console.log(option);
            const update = selectedBrandIds ? [...selectedBrandIds] : [];
            const i = update.findIndex((x) => x === option.id);
            if (i > -1) {
              update.splice(i, 1);
            } else {
              update.push(option.id);
            }
            setSelectedBrandIds(update);
            setUrlParam('brandIds', update.join('_') + '_');
            if (option.id === 'all') {
              removeUrlParam('brandIds');
            }
          },
          defaultOptionText: t('allBrands'),
          onClearAll: () => {
            removeUrlParam('brandIds');
            setSelectedBrandIds([]);
          },
        }
      : undefined;
  }, [brandOptions, selectedBrandIds]);

  return {
    selectedBrandIds,
    brandsFilter,
  };
};

export default useBrandListFilter;
