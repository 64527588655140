import Card, { CardSection } from '../../../elements/card/Card';
import { useTranslation } from 'react-i18next';
import DatePicker from '../../../elements/datepicker/DatePicker';
import { useState } from 'react';
import Button from '../../../elements/button/Button';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useUser } from '../../../contexts/auth/User';
import useFileDownload from '../../../hooks/useFileDownload';
import useManufacturerOptions from '../../../hooks/data/useManufacturerOptions';
import DropdownMenu from '../../../elements/dropdownmenu/DropdownMenu';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import InformationBox from '../../../elements/informationbox/InformationBox';

const OrderReceiptsDownload: React.FC = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.orderReceipts',
  });
  const api = usePetCloudApi();
  const orderReceiptsApi = api.orderReceiptsApi();
  const errorHandler = useErrorHandler();
  const { user } = useUser();

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedManufacturerId, setSelectedManufacturerId] = useState<
    string | null
  >(null);
  const { downloadFromBlob } = useFileDownload();
  const { manufacturerOptions } = useManufacturerOptions();

  const submit = () => {
    setIsSubmitting(true);
    const mId = user?.manufacturerId ?? selectedManufacturerId;
    if (mId && selectedDate) {
      orderReceiptsApi
        .orderReceiptsGetOrderReceiptsDownload(
          mId,
          selectedDate.getFullYear(),
          selectedDate.getMonth() + 1,
          undefined,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          console.log(response);
          downloadFromBlob(response);
          setIsSubmitting(false);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsSubmitting(false);
        });
    }
  };

  const getMaxDate = () => {
    // Get the current date
    const currentDate = new Date();

    // Create a new Date object set to one month before now
    return new Date(currentDate.setMonth(currentDate.getMonth() - 1));
  };

  return (
    <div className={'orderReceipts'}>
      <Card bigScreenWidth={'100%'}>
        <CardSection>
          <InformationBox
            type={'info'}
            title={t('info.title')}
            paragraphs={[t('info.text')]}
          />
          {user?.isProductOwner &&
          manufacturerOptions &&
          manufacturerOptions !== 'NO_PERMISSION' ? (
            <div className={'global-inputGroup'}>
              <div className={'global-inputGroup-input'}>
                <DropdownMenu
                  title={t('manufacturer')}
                  optionObjects={manufacturerOptions}
                  selected={
                    manufacturerOptions.find(
                      (x) => x.id === selectedManufacturerId
                    )?.name
                  }
                  onSelect={(value, id) =>
                    setSelectedManufacturerId(id ?? null)
                  }
                />
              </div>
            </div>
          ) : null}
          <DatePicker
            selected={selectedDate}
            onChange={setSelectedDate}
            dateFormat={'MM/yyyy'}
            maxDate={getMaxDate()}
            showMonthYearPicker
          />
          <div className={'global-cardActions global-cardActions-margin'}>
            <Button
              cta={t('download')}
              width={'minimal'}
              look={'secondary'}
              isLoading={isSubmitting}
              action={submit}
            />
          </div>
        </CardSection>
      </Card>
    </div>
  );
};

export default OrderReceiptsDownload;
