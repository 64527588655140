import './brandcontactaddresses.css';
import Card, { CardSection } from '../../../elements/card/Card';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useEffect, useState } from 'react';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import {
  BrandContactAddressResponse,
  CreateBrandContactAddressRequest,
  Department,
  UpdateBrandContactAddressRequest,
} from '../../../api/petcloudapi/api';
import { LoadingContainer } from '../../../elements/loading/Loading';
import BrandContactAddress from './brandcontactaddress/BrandContactAddress';
import { useTranslation } from 'react-i18next';
import useNotifications from '../../../hooks/useNotifications';
import Button from '../../../elements/button/Button';
import Popup from '../../../elements/popup/Popup';
import { EmptyState } from '../../../elements/emptystate/EmptyState';
import InformationBox from '../../../elements/informationbox/InformationBox';

interface BrandContactAddressesProps {
  brandId: string;
}

const BrandContactAddresses: React.FC<BrandContactAddressesProps> = ({
  brandId,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.brand.contactAddresses',
  });
  const api = usePetCloudApi();
  const brandContactAddressesApi = api.brandContactAddressesApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();

  const [addresses, setAddresses] = useState<
    BrandContactAddressResponse[] | null
  >(null);
  const [newPopup, setNewPopup] = useState(false);
  const [isLoading, setIsLoading] = useState<string | null>(null);
  const [addressToDeleteId, setAddressToDeleteId] = useState<string | null>(
    null
  );

  const getEmptyAddress = (): CreateBrandContactAddressRequest => ({
    brandId: brandId,
    zipCode: '',
    department: 'ProductManufacturer' as Department,
    city: '',
    companyName: '',
  });

  useEffect(() => {
    getAddresses();
  }, []);

  const getAddresses = () => {
    brandContactAddressesApi
      .brandContactAddressesGetBrandContactAddresses(brandId)
      .then((response) => {
        console.log(response);
        setAddresses(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const submitUpdate = (
    id: string,
    address: UpdateBrandContactAddressRequest
  ) => {
    setIsLoading(id);
    brandContactAddressesApi
      .brandContactAddressesUpdateBrandContactAddress(id, address)
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.update_successful'));
        setIsLoading(null);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsLoading(null);
      });
  };

  const postAddress = (address: CreateBrandContactAddressRequest) => {
    setIsLoading('new');
    brandContactAddressesApi
      .brandContactAddressesCreateBrandContactAddress(address)
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.create_successful'));
        setIsLoading(null);
        getAddresses();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsLoading(null);
      });
  };

  const deleteAddress = (id: string) => {
    setIsLoading(id);
    brandContactAddressesApi
      .brandContactAddressesDeleteBrandContactAddress(id)
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.delete_successful'));
        setIsLoading(null);
        setAddressToDeleteId(null);
        getAddresses();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsLoading(null);
      });
  };

  return (
    <div className={'brandContactAddresses'}>
      <Card title={t('title')} bigScreenWidth={'100%'}>
        <CardSection>
          {addresses ? (
            addresses.length > 0 ? (
              addresses.map((address, index) => {
                return (
                  <BrandContactAddress
                    key={index}
                    currentAddress={address}
                    onSubmit={(request) => submitUpdate(address.id, request)}
                    onDelete={() => setAddressToDeleteId(address.id)}
                    isSubmitting={isLoading === address.id}
                    isRetractable
                  />
                );
              })
            ) : (
              <EmptyState />
            )
          ) : (
            <LoadingContainer />
          )}
          <div className={'global-cardActions-margin'}>
            <Button
              cta={t('new')}
              action={() => {
                setNewPopup(true);
              }}
              width={'minimal'}
              look={'secondary'}
            />
          </div>
        </CardSection>
      </Card>
      <Popup
        toggled={newPopup}
        width={'50%'}
        close={() => setNewPopup(false)}
        title={t('newPopup.title')}
      >
        <BrandContactAddress
          currentAddress={getEmptyAddress()}
          onSubmit={postAddress}
          isSubmitting={isLoading === 'new'}
        />
      </Popup>
      {addressToDeleteId ? (
        <Popup
          toggled={true}
          width={'30%'}
          close={() => setAddressToDeleteId(null)}
          title={t('deletePopup.title')}
        >
          <InformationBox
            type={'warning'}
            title={t('deletePopup.warning.title')}
            content={t('deletePopup.warning.text')}
          />
          <Button
            cta={t('deletePopup.cta')}
            action={() => deleteAddress(addressToDeleteId)}
            width={'full'}
            look={'danger'}
            isLoading={isLoading === addressToDeleteId}
          />
        </Popup>
      ) : null}
    </div>
  );
};

export default BrandContactAddresses;
