import { useMemo, useState } from 'react';
import { ListFilterProps } from '../listfilter/ListFilter';
import useUrlParams from '../../../../hooks/useUrlParams';
import useManufacturerOptions from '../../../../hooks/data/useManufacturerOptions';
import { DropdownOption } from '../../../../elements/selectors/Selectors';
import { useTranslation } from 'react-i18next';

type ManufacturerListFilterOptions = {
  presetManufacturerOptions?: DropdownOption[] | null;
  noAllManufacturers?: boolean;
};

const useManufacturerListFilter = (opts?: ManufacturerListFilterOptions) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.useListFilters',
  });
  const { setUrlParam, removeUrlParam, getUrlParam } = useUrlParams();
  const { manufacturerOptions } = useManufacturerOptions({
    noAllManufacturers: opts?.noAllManufacturers,
    presetManufacturerOptions: opts?.presetManufacturerOptions,
  });

  const getManufacturerFromURLParams = () => {
    const manufacturerParam = getUrlParam('manufacturerId');
    if (manufacturerParam && manufacturerParam !== '') {
      return manufacturerParam;
    } else {
      if (!opts?.noAllManufacturers) {
        return 'all';
      } else {
        return undefined;
      }
    }
  };

  const getManufacturersFromURLParams = () => {
    const manufacturersParam = getUrlParam('manufacturerIds');
    if (manufacturersParam && manufacturersParam !== '') {
      const result = manufacturersParam.split('_');
      result.pop();
      return result;
    }
  };

  const [selectedManufacturerId, setSelectedManufacturerId] = useState<
    string | undefined
  >(getManufacturerFromURLParams());

  const getManufacturerOptions = () => {
    return (
      opts?.presetManufacturerOptions ??
      (manufacturerOptions !== 'NO_PERMISSION'
        ? manufacturerOptions
        : undefined)
    );
  };

  const manufacturerFilter: ListFilterProps | undefined = useMemo(() => {
    const options = getManufacturerOptions();

    const selected = opts?.presetManufacturerOptions
      ? opts?.presetManufacturerOptions.find(
          (x) => selectedManufacturerId === x.id
        )?.name
      : manufacturerOptions && manufacturerOptions !== 'NO_PERMISSION'
      ? manufacturerOptions.find((x) => selectedManufacturerId === x.id)?.name
      : [];

    return options
      ? {
          optionObjects: options,
          selected: selected,
          update: (option) => {
            setSelectedManufacturerId(option.id);
            if (option.id !== 'all') {
              setUrlParam('manufacturerId', option.id);
            } else {
              removeUrlParam('manufacturerId');
            }
          },
          onClearAll: () => {
            removeUrlParam('manufacturerId');
            setSelectedManufacturerId(undefined);
          },
        }
      : undefined;
  }, [manufacturerOptions, selectedManufacturerId]);

  const [selectedManufacturerIds, setSelectedManufacturerIds] = useState<
    string[] | undefined
  >(getManufacturersFromURLParams());

  const multiManufacturerFilter: ListFilterProps | undefined = useMemo(() => {
    const options = getManufacturerOptions();

    const selected = opts?.presetManufacturerOptions
      ? opts?.presetManufacturerOptions
          .filter((x) => selectedManufacturerIds?.includes(x.id))
          .map((x) => x.name)
      : manufacturerOptions && manufacturerOptions !== 'NO_PERMISSION'
      ? manufacturerOptions
          .filter((x) => selectedManufacturerIds?.includes(x.id))
          .map((x) => x.name)
      : [];

    return options
      ? {
          optionObjects: options,
          multiSelected: selected,
          update: (option) => {
            console.log(option);
            const update = selectedManufacturerIds
              ? [...selectedManufacturerIds]
              : [];
            const i = update.findIndex((x) => x === option.id);
            if (i > -1) {
              update.splice(i, 1);
            } else {
              update.push(option.id);
            }
            setSelectedManufacturerIds(update);
            setUrlParam('manufacturerIds', update.join('_') + '_');
            if (option.id === 'all') {
              removeUrlParam('manufacturerIds');
            }
          },
          defaultOptionText: t('allManufacturers'),
          onClearAll: () => {
            removeUrlParam('manufacturerIds');
            setSelectedManufacturerIds([]);
          },
        }
      : undefined;
  }, [manufacturerOptions, selectedManufacturerIds]);

  return {
    selectedManufacturerId,
    selectedManufacturerIds,
    manufacturerFilter,
    multiManufacturerFilter,
  };
};

export default useManufacturerListFilter;
