import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import { DropdownOption } from '../../elements/selectors/Selectors';
import { BrandResponse } from '../../api/petcloudapi/api';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import { useTranslation } from 'react-i18next';

export type BrandDict = { [key: string]: BrandResponse };

type BrandOptionsOptions = {
  noAllBrands?: boolean;
  presetBrandOptions?: DropdownOption[] | null;
};

const useBrandOptions = (opts?: BrandOptionsOptions) => {
  const { i18n } = useTranslation();
  const api = usePetCloudApi();
  const brandsApi = api.brandsApi();
  const errorHandler = useErrorHandler();

  const [brandOptions, setBrandOptions] = useState<DropdownOption[] | null>(
    opts?.presetBrandOptions ?? null
  );

  const [brandDict, setBrandDict] = useState<BrandDict | null>(null);

  useEffect(() => {
    brandsApi
      .brandsGetBrands()
      .then((response) => {
        console.log(response);
        const options = response.data.map((brand) => {
          return {
            id: brand.id,
            name:
              brand.name[i18n.language as TranslatedStringIndex] ??
              'brand translation missing',
          };
        });

        // fill dictionary
        const dict: BrandDict = {};
        response.data.forEach((brand) => {
          dict[brand.id] = brand;
        });
        setBrandDict(dict);

        if (!opts?.presetBrandOptions) {
          setBrandOptions(options);
        }
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
    // if (user?.isProductOwner) {
    // } else {
    //   setBrandOptions('NO_PERMISSION');
    //   setBrandDict('NO_PERMISSION');
    // }
  }, []);

  return {
    brandOptions,
    brandDict,
  };
};

export default useBrandOptions;
