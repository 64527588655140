import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import { DropdownOption } from '../../elements/selectors/Selectors';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import { useTranslation } from 'react-i18next';

const useCountryOptions = () => {
  const { i18n } = useTranslation();
  const api = usePetCloudApi();
  const countriesApi = api.countriesApi();
  const errorHandler = useErrorHandler();

  const [countryOptions, setCountryOptions] = useState<DropdownOption[] | null>(
    null
  );

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = () => {
    countriesApi
      .countriesGetCountries()
      .then((response) => {
        console.log(response);
        setCountryOptions(
          response.data.map((country) => ({
            id: country.id,
            name:
              country.name[i18n.language as TranslatedStringIndex] ??
              'missing translation',
          }))
        );
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  return {
    countryOptions,
  };
};

export default useCountryOptions;
