import { Has } from '../../contexts/auth/Authorization';
import { Navigator, Stage } from '../../layout/stage/Stage';
import Button from '../../elements/button/Button';
import Card from '../../elements/card/Card';
import { useTranslation } from 'react-i18next';
import PromotionForm from '../../sections/promotions/promotionform/PromotionForm';
import { useState } from 'react';
import { UpsertPromotionRequest } from '../../api/petcloudapi/api';
import { useUser } from '../../contexts/auth/User';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { Store } from 'react-notifications-component';
import { useNavigate } from 'react-router-dom';
import useDateTools from '../../hooks/useDateTools';
import useManufacturerConditions from '../../hooks/data/useManufacturerConditions';
import { NoAuthorization } from '../../elements/emptystate/EmptyState';

const NewPromotion = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { isPromotionAware } = useManufacturerConditions();
  const { appendTimeZoneOffset } = useDateTools();
  const api = usePetCloudApi();
  const promotionsApi = api.promotionsApi();
  const errorHandler = useErrorHandler();
  const link = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [promotion, setPromotion] = useState<UpsertPromotionRequest>({
    active: false,
    priority: 0,
    name: {
      'de-DE': '',
      'en-GB': '',
    },
    validFrom: appendTimeZoneOffset(
      `${new Date().toISOString().split('T')[0]}T00:00:00.000`
    ),
    validTo: appendTimeZoneOffset(
      `${new Date().toISOString().split('T')[0]}T23:59:59.999`
    ),
    maxRedemptionsGlobal: null,
    maxRedemptionsPerCustomer: null,
    preventCombination: false,
    isValidForWholeAssortment: false,
    code: null,
    useIndividualCodes: false,
    individualCodePrefix: null,
    individualCodeLength: null,
    individualCodeSuffix: null,
    numberOfPromotionCodes: null,
    manufacturerId: user?.manufacturerId,
    discounts: [],
    excludedFromPromotionIds: null,
  });

  const submit = () => {
    setIsSubmitting(true);
    promotionsApi
      .promotionsCreatePromotion(promotion)
      .then((response) => {
        console.log(response);
        Store.addNotification({
          message: t('view.promotion.notifications.create_successful'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
        link(`/promotions/${response.data.id}`);
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  if (isPromotionAware()) {
    return (
      <Has authorizations={['promotions:create']} showNoAuthorization>
        <Stage>
          <Navigator title={t('view.promotions.title')} allowBackButton>
            <Button
              cta={t('actions.save')}
              look={'save'}
              width={'minimal'}
              action={submit}
              isLoading={isSubmitting}
            />
          </Navigator>
          <Card bigScreenWidth="100%">
            <PromotionForm
              promotion={promotion}
              update={(p) => setPromotion(p)}
            />
          </Card>
        </Stage>
      </Has>
    );
  } else {
    return <NoAuthorization />;
  }
};

export default NewPromotion;
