import './measures.css';
import { useState, useEffect, memo } from 'react';
import Input from '../../../elements/input/Input';
import {
  Dropdown,
  DropdownOption,
} from '../../../elements/selectors/Selectors';
import Button from '../../../elements/button/Button';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import {
  ProductResponse,
  CreateProductRequest,
} from '../../../api/petcloudapi/api';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import { LoadingContainer } from '../../../elements/loading/Loading';
import { setErrorTab, Tab, TabConfig, Tabs } from '../../../elements/card/Card';
import languageTabs from '../../../../locate/LanguageTabs';
import { Error, hasError } from '../../../contexts/errorhandler/ErrorHandler';

interface MeasuresProps {
  product: ProductResponse | CreateProductRequest;
  updateProduct?: (product: ProductResponse) => void;
  updateNewProduct?: (product: CreateProductRequest) => void;
  submitCurrentProduct?: () => void;
  buttonActive?: boolean;
  productIsSubmitting?: boolean;
  readonly?: boolean;
  errors?: Error[];
  errorCallback?: () => void;
}

const Measures: React.FC<MeasuresProps> = ({
  product,
  updateProduct,
  updateNewProduct,
  submitCurrentProduct,
  buttonActive,
  productIsSubmitting,
  readonly,
  errors,
  errorCallback,
}) => {
  const { t, i18n } = useTranslation();
  const productUnitsApi = usePetCloudApi().productUnitsApi();
  const [productUnits, setProductUnits] = useState<DropdownOption[] | null>(
    null
  );
  const [currentUnit, setCurrentUnit] = useState<string | undefined>(undefined);
  const [tabs, setTabs] = useState<TabConfig[]>(languageTabs);
  console.log('Measures has rendered.');

  useEffect(() => {
    getProductUnits();
  }, []);

  const getProductUnits = () => {
    productUnitsApi
      .productUnitsGetProductUnits()
      .then((response) => {
        console.log(response);
        const options: DropdownOption[] = [];
        response.data.forEach((unit) => {
          const name = unit.name[i18n.language as TranslatedStringIndex];
          if (name && unit.isBasePriceUnit) {
            const option = {
              name: name,
              id: unit.id,
            };
            options.push(option);
          }
        });
        setProductUnits(options);
        setCurrentUnit(
          options.find((unit) => unit.id === product.productUnitId)?.name
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (productUnits) {
      setCurrentUnit(
        productUnits.find((unit) => unit.id === product.productUnitId)?.name
      );
    }
  }, [product]);

  const getInheritedPackUnit = () => {
    if (updateProduct) {
      const p = product as ProductResponse;
      if (p.parent?.packUnit) {
        return p.parent.packUnit[i18n.language as TranslatedStringIndex];
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  const getInheritedPackUnitPlural = () => {
    if (updateProduct) {
      const p = product as ProductResponse;
      if (p.parent?.packUnitPlural) {
        return p.parent.packUnitPlural[i18n.language as TranslatedStringIndex];
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  const renderUnitTab = (lang: TranslatedStringIndex) => {
    return (
      <div className="card-tab-content">
        <div className="global-inputGroup">
          <div className="global-inputGroup-input">
            <Input
              key={'packUnit'}
              title={t('view.product.measures.packUnit')}
              update={(e) => {
                if (updateProduct) {
                  updateProduct({
                    ...(product as ProductResponse),
                    packUnit: {
                      ...product.packUnit,
                      [lang]: e,
                    },
                  });
                } else if (updateNewProduct) {
                  updateNewProduct({
                    ...(product as CreateProductRequest),
                    packUnit: {
                      ...product.packUnit,
                      [lang]: e,
                    },
                  });
                }
              }}
              content={product.packUnit ? product.packUnit[lang] : null}
              inherited={getInheritedPackUnit()}
              hint={t('view.product.measures.packUnitHint')}
              errors={hasError(
                errors,
                lang === 'de-DE'
                  ? ['Translations[1].PackUnit']
                  : ['Translations[0].PackUnit'],
                () => setErrorTab(tabs, setTabs, lang, true, errorCallback)
              )}
            />
          </div>
          <div className="global-inputGroup-input">
            <Input
              key={'packUnitPlural'}
              title={t('view.product.measures.packUnitPlural')}
              update={(e) => {
                if (updateProduct) {
                  updateProduct({
                    ...(product as ProductResponse),
                    packUnitPlural: {
                      ...product.packUnitPlural,
                      [lang]: e,
                    },
                  });
                } else if (updateNewProduct) {
                  updateNewProduct({
                    ...(product as CreateProductRequest),
                    packUnitPlural: {
                      ...product.packUnitPlural,
                      [lang]: e,
                    },
                  });
                }
              }}
              content={
                product.packUnitPlural ? product.packUnitPlural[lang] : null
              }
              inherited={getInheritedPackUnitPlural()}
              hint={t('view.product.measures.packUnitPluralHint')}
              errors={hasError(
                errors,
                lang === 'de-DE'
                  ? ['Translations[1].PackUnitPlural']
                  : ['Translations[0].PackUnitPlural'],
                () => setErrorTab(tabs, setTabs, lang, true, errorCallback)
              )}
            />
          </div>
        </div>
      </div>
    );
  };

  if (productUnits) {
    return (
      <div className="measures">
        <div className="global-inputGroup">
          <div className="global-inputGroup-input">
            <Input
              title={t('view.product.measures.length')}
              update={(e) => {
                if (updateProduct) {
                  updateProduct({
                    ...(product as ProductResponse),
                    length: parseFloat(e),
                  });
                } else if (updateNewProduct) {
                  updateNewProduct({
                    ...(product as CreateProductRequest),
                    length: parseFloat(e),
                  });
                }
              }}
              content={product.length ? product.length.toString() : null}
              inherited={
                updateProduct
                  ? (product as ProductResponse).parent?.length?.toString()
                  : undefined
              }
              unit={t('units.cm')}
              type="number"
            />
          </div>
          <div className="global-inputGroup-input">
            <Input
              title={t('view.product.measures.width')}
              update={(e) => {
                if (updateProduct) {
                  updateProduct({
                    ...(product as ProductResponse),
                    width: parseFloat(e),
                  });
                } else if (updateNewProduct) {
                  updateNewProduct({
                    ...(product as CreateProductRequest),
                    width: parseFloat(e),
                  });
                }
              }}
              content={product.width ? product.width.toString() : null}
              inherited={
                updateProduct
                  ? (product as ProductResponse).parent?.width?.toString()
                  : undefined
              }
              unit={t('units.cm')}
              type="number"
            />
          </div>
          <div className="global-inputGroup-input">
            <Input
              title={t('view.product.measures.height')}
              update={(e) => {
                if (updateProduct) {
                  updateProduct({
                    ...(product as ProductResponse),
                    height: parseFloat(e),
                  });
                } else if (updateNewProduct) {
                  updateNewProduct({
                    ...(product as CreateProductRequest),
                    height: parseFloat(e),
                  });
                }
              }}
              content={product.height ? product.height.toString() : null}
              inherited={
                updateProduct
                  ? (product as ProductResponse).parent?.height?.toString()
                  : undefined
              }
              unit={t('units.cm')}
              type="number"
            />
          </div>
          <div className="global-inputGroup-input">
            <Input
              title={t('view.product.measures.weight')}
              update={(e) => {
                if (updateProduct) {
                  updateProduct({
                    ...(product as ProductResponse),
                    weight: parseFloat(e),
                  });
                } else if (updateNewProduct) {
                  updateNewProduct({
                    ...(product as CreateProductRequest),
                    weight: parseFloat(e),
                  });
                }
              }}
              content={product.weight ? product.weight.toString() : null}
              inherited={
                updateProduct
                  ? (product as ProductResponse).parent?.weight?.toString()
                  : undefined
              }
              unit={t('units.kg')}
              type="number"
            />
          </div>
        </div>
        <div className="global-inputGroup">
          <div className="global-inputGroup-input">
            <Dropdown
              title={t('view.product.measures.productUnit')}
              optionObjects={productUnits}
              selected={currentUnit}
              required
              inherited={
                (product as ProductResponse).parent
                  ? (product as ProductResponse).parent.productUnit?.name[
                      i18n.language as TranslatedStringIndex
                    ]
                  : undefined
              }
              update={(e) => {
                if (updateProduct) {
                  updateProduct({
                    ...(product as ProductResponse),
                    productUnitId:
                      e.target.selectedOptions[0].getAttribute('data-value'),
                  });
                } else if (updateNewProduct) {
                  updateNewProduct({
                    ...(product as CreateProductRequest),
                    productUnitId:
                      e.target.selectedOptions[0].getAttribute('data-value'),
                  });
                }
              }}
              hint={{
                title: t('view.product.measures.productUnit_hint.title'),
                paragraphs: [
                  t('view.product.measures.productUnit_hint.message'),
                ],
              }}
            />
          </div>
          <div className="global-inputGroup-input">
            <Input
              title={t('view.product.measures.purchaseUnit')}
              required
              update={(e) => {
                if (updateProduct) {
                  updateProduct({
                    ...(product as ProductResponse),
                    purchaseUnit: parseFloat(e),
                  });
                } else if (updateNewProduct) {
                  updateNewProduct({
                    ...(product as CreateProductRequest),
                    purchaseUnit: parseFloat(e),
                  });
                }
              }}
              content={
                product.purchaseUnit ? product.purchaseUnit.toString() : null
              }
              unit={
                currentUnit
                  ? currentUnit
                  : (product as ProductResponse).parent?.productUnit?.name[
                      i18n.language as TranslatedStringIndex
                    ]
              }
              type="number"
              hint={t('view.product.measures.purchaseUnitHint')}
            />
          </div>
          <div className="global-inputGroup-input">
            <Input
              title={t('view.product.measures.referenceUnit')}
              update={(e) => {
                if (updateProduct) {
                  updateProduct({
                    ...(product as ProductResponse),
                    referenceUnit: parseInt(e),
                  });
                } else if (updateNewProduct) {
                  updateNewProduct({
                    ...(product as CreateProductRequest),
                    referenceUnit: parseInt(e),
                  });
                }
              }}
              content={
                product.referenceUnit ? product.referenceUnit.toString() : null
              }
              unit={
                currentUnit
                  ? currentUnit
                  : (product as ProductResponse).parent?.productUnit?.name[
                      i18n.language as TranslatedStringIndex
                    ]
              }
              hint={t('view.product.measures.referenceUnitHint')}
            />
          </div>
        </div>
        <div className="card-tabs-wrapper">
          <Tabs tabs={tabs} parentTab={'#measures'}>
            <Tab>{renderUnitTab('de-DE')}</Tab>
            <Tab>{renderUnitTab('en-GB')}</Tab>
          </Tabs>
        </div>
        {submitCurrentProduct && !readonly ? (
          <div className="measures-actions">
            <Button
              cta={t('actions.save')}
              look="save"
              width="minimal"
              action={submitCurrentProduct}
              active={readonly ? false : buttonActive}
              isLoading={productIsSubmitting}
            />
          </div>
        ) : null}
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

const deepCompare = (prevProps: MeasuresProps, nextProps: MeasuresProps) => {
  return (
    prevProps.product === nextProps.product &&
    prevProps.buttonActive === nextProps.buttonActive &&
    prevProps.productIsSubmitting === nextProps.productIsSubmitting
  );
};

export default memo(Measures, deepCompare);
