import { Navigator, Stage } from '../../layout/stage/Stage';
import { Has } from '../../contexts/auth/Authorization';
import OrderReceiptsDownload from '../../sections/documents/orderreceiptsdownload/OrderReceiptsDownload';
import { useTranslation } from 'react-i18next';

const OrderReceipts = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.orderReceipts',
  });

  return (
    <Stage>
      <Has authorizations={['order_receipts:detail']} showNoAuthorization>
        <Navigator title={t('title')} />
        <OrderReceiptsDownload />
      </Has>
    </Stage>
  );
};

export default OrderReceipts;
