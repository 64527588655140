import { useMemo, useState } from 'react';
import { ListFilterProps } from '../listfilter/ListFilter';
import { useTranslation } from 'react-i18next';
import useUrlParams from '../../../../hooks/useUrlParams';
import { DropdownOption } from '../../../../elements/selectors/Selectors';
import useProductGroupOptions from '../../../../hooks/data/useProductGroupOptions';

type ProductGroupListFilterOptions = {
  noAllProductGroups?: boolean;
  presetProductGroupOptions?: DropdownOption[] | null;
};

const useProductGroupListFilter = (opts?: ProductGroupListFilterOptions) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.useListFilters',
  });
  const { setUrlParam, removeUrlParam, getUrlParam } = useUrlParams();
  const { productGroupOptions } = useProductGroupOptions({
    presetProductGroupOptions: opts?.presetProductGroupOptions,
  });

  const getProductGroupFromURLParams = () => {
    const groupParam = getUrlParam('productGroupId');
    if (groupParam && groupParam !== '') {
      return groupParam;
    } else {
      if (!opts?.noAllProductGroups) {
        return 'all';
      } else {
        return undefined;
      }
    }
  };

  const [selectedProductGroupId, setSelectedProductGroupId] = useState<
    string | undefined
  >(getProductGroupFromURLParams());

  const productGroupFilter: ListFilterProps | undefined = useMemo(() => {
    const options = opts?.presetProductGroupOptions ?? productGroupOptions;

    const selected = opts?.presetProductGroupOptions
      ? opts?.presetProductGroupOptions.find(
          (x) => selectedProductGroupId === x.id
        )?.name
      : productGroupOptions
      ? productGroupOptions.find((x) => selectedProductGroupId === x.id)?.name
      : [];

    return options
      ? {
          optionObjects: options,
          selected: selected,
          defaultOptionText: t('allProductGroups'),
          update: (option) => {
            setSelectedProductGroupId(option.id);
            if (option.id !== 'all') {
              setUrlParam('productGroupId', option.id);
            } else {
              removeUrlParam('productGroupId');
            }
          },
          onClearAll: () => {
            removeUrlParam('productGroupId');
            setSelectedProductGroupId(undefined);
          },
        }
      : undefined;
  }, [productGroupOptions, selectedProductGroupId]);

  const getProductGroupsFromURLParams = () => {
    const groupsParam = getUrlParam('productGroupIds');
    if (groupsParam && groupsParam !== '') {
      const result = groupsParam.split('_');
      result.pop();
      return result;
    } else {
      return null;
    }
  };

  const [selectedProductGroupIds, setSelectedProductGroupIds] = useState<
    string[] | null
  >(getProductGroupsFromURLParams());

  const productGroupsFilter: ListFilterProps | undefined = useMemo(() => {
    const options = opts?.presetProductGroupOptions ?? productGroupOptions;

    const selected = opts?.presetProductGroupOptions
      ? opts?.presetProductGroupOptions
          .filter((x) => selectedProductGroupIds?.includes(x.id))
          .map((x) => x.name)
      : productGroupOptions
      ? productGroupOptions
          .filter((x) => selectedProductGroupIds?.includes(x.id))
          .map((x) => x.name)
      : [];

    return options
      ? {
          optionObjects: options,
          multiSelected: selected,
          update: (option) => {
            console.log(option);
            const update = selectedProductGroupIds
              ? [...selectedProductGroupIds]
              : [];
            const i = update.findIndex((x) => x === option.id);
            if (i > -1) {
              update.splice(i, 1);
            } else {
              update.push(option.id);
            }
            setSelectedProductGroupIds(update);
            setUrlParam('productGroupIds', update.join('_') + '_');
            if (option.id === 'all') {
              removeUrlParam('productGroupIds');
            }
          },
          defaultOptionText: t('allProductGroups'),
          onClearAll: () => {
            removeUrlParam('productGroupIds');
            setSelectedProductGroupIds([]);
          },
        }
      : undefined;
  }, [productGroupOptions, selectedProductGroupIds]);

  return {
    selectedProductGroupIds,
    selectedProductGroupId,
    productGroupFilter,
    productGroupsFilter,
  };
};

export default useProductGroupListFilter;
