import './productlist.css';
import List, { ListRenderObject } from '../list/List';
import { TFunction, useTranslation } from 'react-i18next';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Dropdown, DropdownOption } from '../../elements/selectors/Selectors';
import {
  ManufacturerResponse,
  ProductAssetResponse,
  ProductCategoryResponse,
  ProductGroupResponse,
  ProductListFilterMode,
  ProductState,
  ProductValidationResultResponse,
  ProductVariantResponse,
  PropertyOptionResponse,
  SimpleProductResponse,
  TranslatedTypeResponseOfString,
  WarehouseType,
} from '../../api/petcloudapi/api';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import { useNavigate } from 'react-router-dom';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { Has } from '../../contexts/auth/Authorization';
import { useUser } from '../../contexts/auth/User';
import InformationBox from '../../elements/informationbox/InformationBox';
import Badge from '../../elements/badge/Badge';
import MinifiedVariant from '../../types/MinifiedVariant';
import { ReactComponent as ImagePlaceholder } from '../../../assets/icon/image.svg';
import { ReactComponent as VariantIcon } from '../../../assets/icon/layer.svg';
import { ReactComponent as ParentIcon } from '../../../assets/icon/layer_single.svg';
import i18n from '../../../i18n';
import useUrlParams from '../../hooks/useUrlParams';
import ToggleSwitch from '../../elements/toggleswitch/ToggleSwitch';
import useLocalStorageSettings from '../../hooks/useLocalStorageSettings';
import useListRenderObjects from '../../hooks/list/useListRenderObjects';
import useNotifications from '../../hooks/useNotifications';
import Button from '../../elements/button/Button';
import LazyImage from '../../elements/lazyimage/LazyImage';
import Popup from '../../elements/popup/Popup';
import useManufacturerOptions from '../../hooks/data/useManufacturerOptions';
import CategoryHierarchy from '../categoryhierarchy/CategoryHierarchy';
import useAnimalSpeciesListFilter from '../list/listcontrols/hooks/useAnimalSpeciesListFilter';
import useListControlsSearch from '../list/listcontrols/hooks/useListControlsSearch';
import { HintBox } from '../../elements/hint/Hint';
import useSortValueFunctions from '../../hooks/list/useSortValueFunctions';
import { ListFilterProps } from '../list/listcontrols/listfilter/ListFilter';
import useBrandListFilter from '../list/listcontrols/hooks/useBrandListFilter';
import useManufacturerListFilter from '../list/listcontrols/hooks/useManufacturerListFilter';
import useProductGroupListFilter from '../list/listcontrols/hooks/useProductGroupListFilter';

type ProductFilterObject = {
  manufacturerIds?: string[];
  brandIds?: string[];
  animalSpeciesIds?: string[];
  productGroupId?: string;
  state?: ProductState;
  productListFilterMode?: ProductListFilterMode;
  categoryIds?: string[];
};

interface ProductListProps {
  finishedLoadingCallback?: (products: SimpleProductResponse[]) => void;
  selectProductCallback?: (
    product: SimpleProductResponse,
    action: 'select' | 'deselect'
  ) => void;
  selectAllProductsCallback?: (products: SimpleProductResponse[]) => void;
  allowActions?: boolean;
  allowApprovalWarning?: boolean;
  selectedIds?: string[] | null;
  filterFunc?: {
    infoBox: { title: string; message: string };
    func: (product: SimpleProductResponse) => boolean;
  };
  productListFilterMode?: ProductListFilterMode;
  warehouseType?: WarehouseType;
  height?: number | string;
  unselectableIds?: string[];
  prefetchedProducts?:
    | SimpleProductResponse[]
    | ProductValidationResultResponse[];
  adjustHeightToViewportOffset?: number;
  disableSelectAll?: boolean;
  renderObjects?: ListRenderObject[];
  disableURLParams?: boolean;
  disableAdjustHeightToViewport?: boolean;
  listConstrolsFilters?: ListFilterProps[];
  hideStockQuantity?: boolean;
}

const ProductList: React.FC<ProductListProps> = ({
  finishedLoadingCallback,
  selectProductCallback,
  selectAllProductsCallback,
  allowActions,
  allowApprovalWarning,
  selectedIds,
  filterFunc,
  productListFilterMode,
  warehouseType,
  height,
  unselectableIds,
  prefetchedProducts,
  adjustHeightToViewportOffset,
  disableSelectAll,
  renderObjects,
  disableURLParams,
  disableAdjustHeightToViewport,
  listConstrolsFilters,
  hideStockQuantity,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.products',
  });
  const { t: tT } = useTranslation();
  const link = useNavigate();
  const api = usePetCloudApi();
  const productsApi = api.productsApi();
  const productCategoriesApi = api.productCategoriesApi();
  const errorHandler = useErrorHandler();
  const { user } = useUser();
  const { setUrlParam, getUrlParam } = useUrlParams();
  const { getToggledSetting, setToggledSetting } = useLocalStorageSettings();
  const { renderLimitedString, renderManufacturer } = useListRenderObjects();
  const { pushNotification } = useNotifications();
  const { manufacturerOptions, manufacturerDict } = useManufacturerOptions();
  const { selectedAnimalSpeciesIds, animalSpeciesFilter } =
    useAnimalSpeciesListFilter();
  const { selectedBrandIds, brandsFilter } = useBrandListFilter();
  const { selectedManufacturerIds, multiManufacturerFilter } =
    useManufacturerListFilter();
  const { selectedProductGroupId, productGroupFilter } =
    useProductGroupListFilter({ noAllProductGroups: true });

  const { translatedValue } = useSortValueFunctions();

  const [selectedProducts, setSelectedProducts] = useState<
    SimpleProductResponse[]
  >([]);
  const [selectedProductFilters, setSelectedProductFilters] =
    useState<ProductFilterObject | null>(null);
  const [productCategories, setProductCategories] = useState<
    ProductCategoryResponse[] | null
  >(null);
  const [isUpdatingState, setIsUpdatingState] = useState(false);
  const [stateUpdatePopup, setStateUpdatePopup] = useState(false);
  const [stateUpdatePopupState, setStateUpdatePopupState] = useState<
    string | null
  >(null);
  const [isVariantsMode, setIsVariantsMode] = useState(
    getToggledSetting('productList_showVariants')
  );
  const [isFilterConfirm, setIsFilterConfirm] = useState(
    getToggledSetting('productList_isFilterConfirm')
  );
  const [categoriesFilterPopup, setCategoriesFilterPopup] = useState(false);

  // this is temporal for checking the boxes in the popup,
  // and once the user clicks a button, they get saved in the selectedProductFilters.
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<
    string[] | null
  >(null);
  const [isValidating, setIsValidating] = useState(false);
  const { listControlSearch, currentItems, setOriginalItems, query } =
    useListControlsSearch<SimpleProductResponse>(
      t('search'),
      isFilterConfirm ? [] : null,
      [
        'name.de-DE',
        'name.en-GB',
        'ean',
        'productNumber',
        'manufacturerProductNumber',
      ]
    );

  const productStateOptions = [
    {
      name: t('filters.allStates'),
      id: 'all',
    },
    {
      name: tT('list.states.Active'),
      id: 'Active',
    },
    {
      name: tT('list.states.Inactive'),
      id: 'Inactive',
    },
    {
      name: tT('list.states.Archived'),
      id: 'Archived',
    },
    {
      name: tT('list.states.ForApproval'),
      id: 'ForApproval',
    },
    {
      name: tT('list.states.Draft'),
      id: 'Draft',
    },
  ];

  useEffect(() => {
    checkURLForFilters();
    getProductCategoryOptions();
  }, []);

  useEffect(() => {
    if (selectedIds && currentItems) {
      setSelectedProducts(
        currentItems.filter((p) => selectedIds.includes(p.id))
      );
    }
  }, [selectedIds, currentItems]);

  const checkURLForFilters = () => {
    const initialFilters: ProductFilterObject = {
      productGroupId: undefined,
      manufacturerIds: undefined,
      state: undefined,
      productListFilterMode: undefined,
      categoryIds: undefined,
    };
    const productGroupParam = getUrlParam('productGroupId');
    if (productGroupParam && productGroupParam !== 'all') {
      initialFilters.productGroupId = productGroupParam;
    }
    const stateParam = getUrlParam('state');
    if (stateParam && stateParam !== 'all') {
      initialFilters.state = stateParam as ProductState;
    }
    const manufacturerIdsParam = getUrlParam('manufacturerIds')?.split('_');
    if (manufacturerIdsParam && !manufacturerIdsParam.includes('all')) {
      manufacturerIdsParam.pop();
      initialFilters.manufacturerIds = manufacturerIdsParam;
    }
    const brandIdsParam = getUrlParam('brandIds')?.split('_');
    if (brandIdsParam && !brandIdsParam.includes('all')) {
      brandIdsParam.pop();
      initialFilters.brandIds = brandIdsParam;
    }
    const animalSpeciesIdsParam = getUrlParam('animalSpeciesIds')?.split('_');
    if (animalSpeciesIdsParam && !animalSpeciesIdsParam.includes('all')) {
      animalSpeciesIdsParam.pop();
      initialFilters.animalSpeciesIds = animalSpeciesIdsParam;
    }
    const categoriesParam = getUrlParam('categories');
    if (categoriesParam && categoriesParam !== 'all') {
      const result = categoriesParam.split('_');
      result.pop();
      initialFilters.categoryIds = result;
      setSelectedCategoryIds(result);
    }
    console.log('URL filters are: ', initialFilters);
    setSelectedProductFilters(initialFilters);
  };

  useEffect(() => {
    if (manufacturerOptions && manufacturerDict && !isFilterConfirm) {
      getProducts();
    }
  }, [
    selectedProductFilters,
    isVariantsMode,
    prefetchedProducts,
    manufacturerOptions,
    manufacturerDict,
  ]);

  const sortProductColumns = (products: SimpleProductResponse[]) => {
    return products.map((product) => {
      const { name, cover, productGroup, state, ...rest } = product;
      return {
        cover: cover,
        name: name,
        productGroup: productGroup,
        state: state,
        ...rest,
      };
    });
  };

  const findCategoriesByIds = (
    ids: string[],
    categories: ProductCategoryResponse[]
  ): ProductCategoryResponse[] => {
    return categories.reduce((acc: ProductCategoryResponse[], category) => {
      if (ids.includes(category.id)) {
        acc.push(category);
      }
      if (category.children) {
        acc = acc.concat(findCategoriesByIds(ids, category.children));
      }
      return acc;
    }, []);
  };

  const getAllNestedCategoryIds = (
    parentCategories: ProductCategoryResponse[]
  ): string[] => {
    return parentCategories.reduce((acc: string[], category) => {
      acc.push(category.id);
      if (category.children) {
        acc = acc.concat(getAllNestedCategoryIds(category.children));
      }
      return acc;
    }, []);
  };

  const getProducts = () => {
    if (!prefetchedProducts) {
      setOriginalItems(null);
      setSelectedProducts([]);
      if (selectedProductFilters) {
        console.log('initial filters are: ', selectedProductFilters);
        productsApi
          .productsGetSimpleProductsList(
            selectedProductFilters.manufacturerIds,
            selectedProductFilters.brandIds,
            selectedProductFilters.animalSpeciesIds,
            selectedProductFilters.productGroupId,
            selectedProductFilters.state,
            getToggledSetting('productList_showVariants')
              ? undefined
              : productListFilterMode,
            warehouseType,
            undefined,
            selectedProductFilters.categoryIds && productCategories
              ? getAllNestedCategoryIds(
                  findCategoriesByIds(
                    selectedProductFilters.categoryIds,
                    productCategories
                  )
                )
              : undefined
          )
          .then(async (response) => {
            console.log(response);
            let data: SimpleProductResponse[];
            if (filterFunc) {
              data = response.data.filter(filterFunc.func);
            } else {
              data = response.data;
            }

            // add manufacturer info to each entry
            const dataWithManufacturers = enrichProductsWithManufacturer(data);

            const sorted = sortProductColumns(dataWithManufacturers);
            setOriginalItems(sorted);
          })
          .catch((error) => {
            console.log(error);
            errorHandler.addError(error.response);
          });
      }
    } else {
      const enriched = enrichProductsWithManufacturer(prefetchedProducts);
      const sorted = sortProductColumns(enriched);
      setOriginalItems(sorted);
    }
  };

  const validateSelectedProducts = () => {
    setIsValidating(true);
    const ids = selectedProducts.map((product) => product.id);
    productsApi
      .productsValidateProductsById(ids)
      .then((response) => {
        console.log(response);
        pushNotification(
          t('notifications.validationSubmission_successful'),
          'info'
        );
        setIsValidating(false);
      })
      .catch((error) => {
        console.log(error);
        setIsValidating(false);
      });
  };

  const enrichProductsWithManufacturer = (data: SimpleProductResponse[]) => {
    if (manufacturerDict && manufacturerDict !== 'NO_PERMISSION') {
      return data.map((x) => ({
        ...x,
        manufacturer: manufacturerDict[x.manufacturerId],
      }));
    } else {
      return data;
    }
  };

  useEffect(() => {
    if (finishedLoadingCallback && currentItems) {
      finishedLoadingCallback(currentItems);
    }
  }, [currentItems]);

  const selectProduct = (item: SimpleProductResponse) => {
    if (item.id && currentItems) {
      const selectedProduct = selectedProducts.find(
        (product) => product.id === item.id
      );
      const update = [...selectedProducts];
      if (selectedProduct !== undefined) {
        const index = selectedProducts.indexOf(selectedProduct);
        update.splice(index, 1);
        setSelectedProducts([...update]);
        if (selectProductCallback) {
          selectProductCallback(item, 'deselect');
        }
      } else {
        const productToAdd = currentItems.find(
          (product) => product.id === item.id
        );
        if (productToAdd) {
          update.push(productToAdd);
          setSelectedProducts([...update]);
          if (selectProductCallback) {
            selectProductCallback(productToAdd, 'select');
          }
        }
      }
    }
  };

  const selectProductsTranche = (selection: SimpleProductResponse[]) => {
    if (currentItems) {
      const trancheAlreadySelected = selection.every((x) =>
        selectedProducts.includes(x)
      );

      if (trancheAlreadySelected) {
        const removedTranche = [...selectedProducts];
        selection.forEach((item) => {
          const i = removedTranche.findIndex((x) => x.id === item.id);
          if (i !== -1) {
            removedTranche.splice(i, 1);
          }
        });

        setSelectedProducts(removedTranche);
        if (selectAllProductsCallback) {
          selectAllProductsCallback(removedTranche);
        }
      } else {
        const result = [...selectedProducts];
        selection.forEach((item) => {
          if (!result.includes(item)) {
            result.push(item);
          }
        });
        setSelectedProducts(result);
        if (selectAllProductsCallback) {
          selectAllProductsCallback(result);
        }
      }
    }
  };

  const selectAllProducts = () => {
    if (currentItems) {
      if (selectedProducts.length === currentItems.length) {
        setSelectedProducts([]);
        if (selectAllProductsCallback) {
          selectAllProductsCallback([]);
        }
      } else {
        setSelectedProducts(currentItems);
        if (selectAllProductsCallback) {
          selectAllProductsCallback(currentItems);
        }
      }
    }
  };

  const openSelectedProductsInBulkEditor = (copy?: boolean) => {
    localStorage.setItem(
      'productBulkEditor_loadIds',
      JSON.stringify(selectedProducts.map((x) => x.id))
    );
    window.open(
      `/productBulkEditor?loadIds=true${copy ? '&copy=true' : ''}${
        isVariantsMode ? '&directIds=true' : ''
      }`
    );
  };

  const getProductCategoryOptions = () => {
    productCategoriesApi
      .productCategoriesGetProductCategories(true)
      .then((response) => {
        console.log(response);
        setProductCategories(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getCurrentProductStateFilter = () => {
    if (selectedProductFilters?.state) {
      const selectedState = selectedProductFilters.state;
      const current = productStateOptions.find(
        (option) => option.id === selectedState
      );
      if (current === undefined) {
        return t('filters.allStates');
      } else {
        return current.name;
      }
    } else {
      return t('filters.allStates');
    }
  };

  const updateProductStateFilter = (productStateOption: DropdownOption) => {
    console.log('updating product state filter');
    if (productStateOption.id !== 'all') {
      setSelectedProductFilters({
        ...selectedProductFilters,
        state: productStateOption.id as ProductState,
      });
    } else {
      setSelectedProductFilters({
        ...selectedProductFilters,
        state: undefined,
      });
    }
    if (!disableURLParams) {
      setUrlParam('state', productStateOption.id);
    }
  };

  useEffect(() => {
    if (selectedProductFilters) {
      setSelectedProductFilters({
        ...selectedProductFilters,
        manufacturerIds:
          selectedManufacturerIds && !selectedManufacturerIds.includes('all')
            ? selectedManufacturerIds
            : undefined,
        brandIds:
          selectedBrandIds && !selectedBrandIds.includes('all')
            ? selectedBrandIds
            : undefined,
        animalSpeciesIds:
          selectedAnimalSpeciesIds && !selectedAnimalSpeciesIds.includes('all')
            ? selectedAnimalSpeciesIds
            : undefined,
        productGroupId: selectedProductGroupId,
      });
    }
  }, [
    selectedManufacturerIds,
    selectedBrandIds,
    selectedAnimalSpeciesIds,
    selectedProductGroupId,
  ]);

  const updateProductCategoriesFilter = (ids: string[]) => {
    setSelectedProductFilters({
      ...selectedProductFilters,
      categoryIds: ids,
    });
    let idString = '';
    ids.forEach((id) => (idString = idString += `${id}_`));
    if (!disableURLParams) {
      setUrlParam('categories', idString);
    }
  };

  const hasProductsAwaitingApproval = (
    array?: SimpleProductResponse[] | null
  ) => {
    return array?.find((p) => p.state === 'ForApproval');
  };

  const updateSelectedProductStates = () => {
    setIsUpdatingState(true);
    if (stateUpdatePopupState) {
      productsApi
        .productsBulkUpdateProductStates({
          productState: stateUpdatePopupState as ProductState,
          productIds: selectedProducts.map((x) => x.id),
        })
        .then((response) => {
          console.log(response);
          pushNotification(t('notifications.stateUpdate_success'));
          setIsUpdatingState(false);
          setStateUpdatePopup(false);
        })
        .catch((error) => {
          console.log(error.response);
          errorHandler.addError(error.response);
          setIsUpdatingState(false);
        });
    }
  };

  const renderVariantOptions = useCallback(
    (options: PropertyOptionResponse[]) => {
      return (
        <div className={'productList-variantOptionBadges'}>
          {options.map((option) => {
            return (
              <div className={'productList-variantOptionBadge'}>
                <Badge
                  color={'var(--color-inherited)'}
                  title={
                    option.name[i18n.language as TranslatedStringIndex] ??
                    'translation_error'
                  }
                />
              </div>
            );
          })}
        </div>
      );
    },
    []
  );

  const renderListFilterModeSwitch = () => {
    return (
      <ToggleSwitch
        toggled={isFilterConfirm}
        toggle={() => {
          setIsFilterConfirm(!isFilterConfirm);
          setToggledSetting('productList_isFilterConfirm', !isFilterConfirm);
        }}
        label={t('settings.isFilterConfirm')}
      />
    );
  };

  const renderListVariantModeSwitch = () => {
    return (
      <ToggleSwitch
        toggled={isVariantsMode}
        toggle={() => {
          setIsVariantsMode(!isVariantsMode);
          setToggledSetting('productList_showVariants', !isVariantsMode);
        }}
        label={t('settings.showVariants')}
      />
    );
  };

  const renderProductCategoryButton = useCallback(() => {
    const count = selectedCategoryIds?.length;
    return (
      <Button
        cta={
          count
            ? `${count} ${t('filters.categories')}`
            : t('filters.allCategories')
        }
        action={() => setCategoriesFilterPopup(true)}
        look={'secondary'}
        width={'minimal'}
        helperCSSClass={'productList-listFilter-button'}
      />
    );
  }, [selectedCategoryIds]);

  const renderProductGroup = useCallback(
    (productGroup: ProductGroupResponse) => {
      return (
        <div className={'list-table-td'}>
          {productGroup.name[i18n.language as TranslatedStringIndex]}
        </div>
      );
    },
    []
  );

  const renderName = useCallback(
    (name: TranslatedTypeResponseOfString, product: SimpleProductResponse) => {
      return (
        <div className={'productList-name'}>
          {product.hasVariants ? (
            <VariantIcon
              className="productList-name-icon"
              fill="var(--color-text_tertiary)"
            />
          ) : isVariantsMode && (!product.ean || product.ean === '') ? (
            <ParentIcon
              className="productList-name-icon"
              fill="var(--color-text_tertiary)"
            />
          ) : null}
          {renderLimitedString(
            name[i18n.language as TranslatedStringIndex],
            300,
            query
          )}
        </div>
      );
    },
    [query, isVariantsMode]
  );

  const getIgnore = () => {
    const arr = [
      'manufacturerId',
      'id',
      'description',
      'releaseDate',
      'children',
      'activeProductVersionId',
      'hasVariants',
      'parentId',
      'coverId',
      'updatedAt',
    ];
    if (productListFilterMode !== ProductListFilterMode.ExcludeNonBuyable) {
      arr.push('variantOptions');
    }
    if (hideStockQuantity) {
      arr.push('inStockQuantity');
    }
    return arr;
  };

  const navigateToProduct = (product: SimpleProductResponse) => {
    if (allowActions) {
      if (product.parentId) {
        link(`/products/variant/${product.id}`);
      } else {
        link(`/products/${product.id}`);
      }
    }
  };

  const renderOnItemCountHoverNode = useCallback(() => {
    const uniqueManufacturerIds: string[] = [];
    currentItems?.forEach((m) => {
      if (!uniqueManufacturerIds.includes(m.manufacturerId)) {
        uniqueManufacturerIds.push(m.manufacturerId);
      }
    });
    const manufacturerCount = uniqueManufacturerIds.length;
    if (user?.isProductOwner) {
      return (
        <HintBox
          paragraphs={[`${t('uniqueManufacturerIds')}: ${manufacturerCount}`]}
          isToolTip
        />
      );
    } else {
      return null;
    }
  }, [currentItems]);

  const getRenderObjects = () => {
    const r: ListRenderObject[] = [
      {
        key: 'name',
        renderMethod: renderName,
      },
      {
        key: 'productGroup',
        renderMethod: renderProductGroup,
      },
      {
        key: 'state',
        renderMethod: (value, item) =>
          renderProductStateInList(value, item, tT),
      },
      {
        key: 'cover',
        renderMethod: (value, item) =>
          renderProductCover(
            value,
            item,
            i18n.language as TranslatedStringIndex
          ),
        receiveNullValues: true,
      },
      {
        key: 'manufacturer',
        renderMethod: renderManufacturer,
      },
      {
        key: 'variantOptions',
        renderMethod: (value) => renderVariantOptions(value),
      },
    ];
    if (renderObjects) {
      return r.concat(renderObjects);
    } else {
      return r;
    }
  };

  return (
    <div className={'productList'}>
      {user?.isProductOwner &&
      allowApprovalWarning &&
      hasProductsAwaitingApproval(currentItems) ? (
        <InformationBox
          title={t('approvalHeadsUp.title')}
          content={t('approvalHeadsUp.text')}
          type={'info'}
        />
      ) : null}
      {filterFunc ? (
        <InformationBox
          title={filterFunc.infoBox.title}
          content={filterFunc.infoBox.message}
          type={'info'}
        />
      ) : null}
      <Has
        authorizations={['products:list', 'product_categories:list']}
        showNoAuthorization
      >
        <List
          items={currentItems}
          onSelect={selectProduct}
          onSelectAll={disableSelectAll ? undefined : selectAllProducts}
          onSelectTranche={disableSelectAll ? undefined : selectProductsTranche}
          selectedItems={selectedProducts}
          unselectableIds={unselectableIds}
          onRowClick={navigateToProduct}
          translatedStrings={['name', 'description']}
          dateStrings={['releaseDate', 'createdAt']}
          height={height}
          linkedKey={'id'}
          linkPrefix={(item: SimpleProductResponse) =>
            `/products/${item.parentId ? 'variant/' : ''}`
          }
          monoSpaceStrings={[
            'productNumber',
            'manufacturerProductNumber',
            'ean',
          ]}
          clipboardStrings={['productNumber']}
          ignore={getIgnore()}
          itemImgKey={'cover'}
          actions={
            allowActions
              ? [
                  {
                    cta: 'edit',
                    look: 'blue',
                    action: (item: SimpleProductResponse) =>
                      link(
                        `/products/${item.parentId ? 'variant/' : ''}` + item.id
                      ),
                  },
                  {
                    cta: 'viewParent',
                    look: 'blue',
                    action: (item: SimpleProductResponse) =>
                      link('/products/' + item.parentId),
                    hide: (item: SimpleProductResponse) => !item.parentId,
                  },
                ]
              : undefined
          }
          queryString={query}
          queryKeys={[
            'name',
            'ean',
            'productNumber',
            'manufacturerProductNumber',
          ]}
          sortValueFunctions={{
            name: (product: SimpleProductResponse) =>
              translatedValue(product, 'name'),
            productGroup: (product: SimpleProductResponse) =>
              translatedValue(product, 'productGroup'),
            manufacturer: (
              product: SimpleProductResponse & {
                manufacturer: ManufacturerResponse;
              }
            ) => {
              return product.manufacturer?.companyName?.toLowerCase();
            },
            state: getStateSortIndex,
          }}
          renderObjects={getRenderObjects()}
          memoScrollKey={'productList'}
          memoScrollConditionFunc={() => {
            return localStorage.getItem('previousPage') === 'productDetailView';
          }}
          tableHeadContrast
          isShowingIndex
          adjustHeightToViewport={!disableAdjustHeightToViewport}
          adjustHeightToViewportOffset={adjustHeightToViewportOffset}
          isNewTabLink={allowActions}
          wrapInNewTabLink={['name']}
          actionsBar={
            allowActions
              ? {
                  buttons: [
                    <Button
                      cta={tT('actions.updateState')}
                      look={'secondary-danger'}
                      action={() => setStateUpdatePopup(true)}
                      width={'tiny'}
                      margin={'right'}
                    />,
                    <Button
                      cta={tT('actions.validate')}
                      look={'secondary'}
                      action={validateSelectedProducts}
                      width={'tiny'}
                      margin={'right'}
                      isLoading={isValidating}
                    />,
                    <Button
                      cta={tT('actions.editInBulk')}
                      look={'secondary'}
                      action={() => openSelectedProductsInBulkEditor(false)}
                      width={'tiny'}
                      margin={'right'}
                    />,
                    <Button
                      cta={tT('actions.copy')}
                      look={'secondary'}
                      action={() => openSelectedProductsInBulkEditor(true)}
                      width={'tiny'}
                    />,
                  ],
                }
              : undefined
          }
          listControls={{
            search: listControlSearch,
            onItemCountHoverNode: renderOnItemCountHoverNode,
            filters: !prefetchedProducts
              ? [
                  productGroupFilter,
                  {
                    optionObjects: productStateOptions,
                    update: updateProductStateFilter,
                    selected: getCurrentProductStateFilter(),
                    nativeDropdown: true,
                  },
                  multiManufacturerFilter,
                  brandsFilter,
                  animalSpeciesFilter,
                  {
                    renderMethod: renderProductCategoryButton,
                  },
                ]
              : listConstrolsFilters ?? undefined,
            children: isFilterConfirm ? (
              <Button
                cta={t('filters.apply')}
                look={'save'}
                action={getProducts}
              />
            ) : undefined,
            settings:
              !prefetchedProducts && !!allowActions
                ? [
                    {
                      renderMethod: renderListFilterModeSwitch,
                    },
                    { renderMethod: renderListVariantModeSwitch },
                  ]
                : undefined,
          }}
          pageItemCount={[25, 50, 100]}
        />
      </Has>
      <Popup
        toggled={stateUpdatePopup}
        width={'20%'}
        close={() => setStateUpdatePopup(false)}
      >
        <div className={'popup-title'}>{t('stateUpdatePopup.title')}</div>
        <InformationBox
          type={'info'}
          content={t('stateUpdatePopup.text')}
          maxWidth={400}
        />
        <div className={'global-inputGroup'}>
          <div className={'global-inputGroup-input'}>
            <Dropdown
              title={t('stateUpdatePopup.newState')}
              optionObjects={productStateOptions.filter((x) => {
                if (x.id === 'all') {
                  return false;
                } else if (user?.isProductOwner) {
                  return true;
                } else {
                  return x.id !== 'Active';
                }
              })}
              selected={
                productStateOptions.find((o) => o.id === stateUpdatePopupState)
                  ?.name
              }
              update={(e) => {
                const x =
                  e.currentTarget.selectedOptions[0].getAttribute('data-value');
                if (x) setStateUpdatePopupState(x);
              }}
            />
          </div>
        </div>
        <div className={'global-cardActions'}>
          <Button
            cta={t('stateUpdatePopup.cta')}
            width={'full'}
            look={'save'}
            isLoading={isUpdatingState}
            action={updateSelectedProductStates}
          />
        </div>
      </Popup>
      {productCategories && categoriesFilterPopup ? (
        <Popup
          width={'40%'}
          toggled={true}
          close={() => setCategoriesFilterPopup(false)}
        >
          <div className={'popup-title'}>{t('categoryFilterPopup.title')}</div>
          <CategoryHierarchy
            availableProductCategories={productCategories}
            currentProductCategoriesIds={selectedCategoryIds ?? []}
            updateCurrentProductCategories={(categories) =>
              setSelectedCategoryIds(categories.map((x) => x.id))
            }
          />
          <div className={'global-cardActions-margin'}>
            <Button
              cta={t('categoryFilterPopup.cta')}
              width={'minimal'}
              look={'secondary'}
              action={
                selectedCategoryIds
                  ? () => {
                      updateProductCategoriesFilter(selectedCategoryIds);
                      setCategoriesFilterPopup(false);
                    }
                  : undefined
              }
            />
          </div>
        </Popup>
      ) : null}
    </div>
  );
};

export default ProductList;

export const renderProductCover = (
  cover: ProductAssetResponse,
  item: SimpleProductResponse,
  lang: TranslatedStringIndex
) => {
  const coverUri = cover?.asset[lang]?.uri;
  if (coverUri) {
    return (
      <LazyImage
        src={coverUri}
        alt={'Thumbnail'}
        className={'list-table-td-img-img'}
      />
    );
  } else {
    return (
      <ImagePlaceholder
        className={'list-table-td-img-imgPlaceholder'}
        fill={'var(--color-border)'}
      />
    );
  }
};

export const getStateSortIndex = (product: SimpleProductResponse) => {
  if (product.state === 'ForApproval') {
    return 0;
  }
  if (product.activeProductVersionId) {
    return 1;
  } else if (product.state === 'Archived') {
    return 2;
  } else {
    return 3;
  }
};

export const renderProductStateInList = (
  productState: ProductState,
  item: SimpleProductResponse,
  t: TFunction
) => {
  const nodes: ReactNode[] = [];
  if (item.activeProductVersionId) {
    nodes.push(
      <Badge title={t('list.states.Active')} color={'var(--color-success)'} />
    );
  } else if (productState === 'Archived') {
    nodes.push(
      <Badge title={t('list.states.Archived')} color={'var(--color-gray)'} />
    );
  } else {
    nodes.push(
      <Badge title={t('list.states.Inactive')} color={'var(--color-danger)'} />
    );
  }
  if (productState === 'ForApproval') {
    nodes.push(
      <Badge
        title={t('list.states.ForApproval')}
        color={'var(--color-primary)'}
      />
    );
  }

  return (
    <div className="customBadge-array">
      {nodes.map((node, index) => {
        return (
          <div key={index} className="customBadge-margin">
            {node}
          </div>
        );
      })}
    </div>
  );
};

export const minifyVariants = (
  vars: ProductVariantResponse[] | null | undefined,
  showArchived?: boolean
) => {
  if (vars) {
    const lang = i18n.language as TranslatedStringIndex;
    const results: MinifiedVariant[] = [];
    vars.forEach((variant) => {
      const productAssets = variant.productAssets;
      if (variant.state !== 'Archived' || showArchived) {
        results.push({
          id: variant.id,
          activeProductVersionId: variant.activeProductVersionId,
          coverUri:
            productAssets && productAssets.length > 0
              ? productAssets[0].asset[lang]?.uri
              : undefined,
          name: variant.name[lang],
          state: variant.state,
          productNumber: variant.productNumber,
          ean: variant.ean,
        });
      }
    });
    return results;
  } else {
    return null;
  }
};
