import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Store } from 'react-notifications-component';
import { Has, useAuthorization } from '../../contexts/auth/Authorization';
import {
  Navigator,
  Stage,
  StageColumn,
  StageColumns,
} from '../../layout/stage/Stage';
import Card, {
  CardSection,
  HelperCard,
  setErrorTab,
  setMultipleErrorTabs,
  Tab,
  TabConfig,
  Tabs,
} from '../../elements/card/Card';
import Button from '../../elements/button/Button';
import { usePetCloudApi } from '../../api/PetCloudApi';
import {
  ProductGroupMappingResponse,
  ProductGroupResponse,
  ProductIdentifierType,
  ProductResponse,
  ProductVariantResponse,
  ProductVersionResponse,
  PropertyGroupResponse,
  TaxTypeIdentifier,
  UpdateProductRequest,
} from '../../api/petcloudapi/api';
import {
  addErrors,
  Error,
  hasError,
  removeErrors,
  useErrorHandler,
} from '../../contexts/errorhandler/ErrorHandler';
import { LoadingContainer } from '../../elements/loading/Loading';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import Meta from '../../sections/product/meta/Meta';
import Media from '../../sections/product/media/Media';
import Pricing from '../../sections/product/pricing/Pricing';
import Stocks from '../../sections/product/stocks/Stocks';
import Measures from '../../sections/product/measures/Measures';
import Shipping from '../../sections/product/shipping/Shipping';
import CartOptions from '../../sections/product/cartoptions/CartOptions';
import Properties from '../../sections/product/properties/Properties';
import Categories from '../../sections/product/categories/Categories';
import Variants from '../../sections/product/variants/Variants';
import StockSettings from '../../sections/product/stocksettings/StockSettings';
import Regulations from '../../sections/product/regulations/Regulations';
import ProductAdditionalInformation from '../../sections/product/additionalinformation/AdditionalInformation';
import AnalyticConstituents from '../../sections/product/analyticconstituents/AnalyticConstituents';
import Information from '../../sections/product/information/Information';
import StateManagement from '../../sections/product/statemanagement/StateManagement';
import Versions from '../../sections/product/versions/Versions';
import BrandProductLine from '../../sections/product/brandproductline/BrandProductLine';
import { ReactComponent as Prohibit } from '../../../assets/icon/prohibit.svg';
import { ReactComponent as IconArrowUp } from '../../../assets/icon/up-arrow.svg';
import Popup from '../../elements/popup/Popup';
import InformationBox from '../../elements/informationbox/InformationBox';
import useHelperPopup from '../../hooks/useHelperPopup';
import { ReactComponent as Eye } from '../../../assets/icon/eye.svg';
import { ReactComponent as IconLinked } from '../../../assets/icon/linked.svg';
import useStoreHost from '../../hooks/useStoreHost';
import useManufacturerConditions from '../../hooks/data/useManufacturerConditions';
import AnimalSpeciesAndProductGroup from '../../sections/product/animalspeciesandproductgroup/AnimalSpeciesAndProductGroup';
import useAdditionalPropertyValidationRules from '../../hooks/product/useAdditionalPropertyValidationRules';
import Kcal from '../../sections/product/kcal/Kcal';
import ErrorsOverview from '../../sections/productvalidation/errorsoverview/ErrorsOverview';
import useStorageData from '../../hooks/useStorageData';
import useUserTools from '../../hooks/useUserTools';
import VariantOptions from '../../sections/product/variantoptions/VariantOptions';
import useUrlParams from '../../hooks/useUrlParams';

interface ProductProps {
  isVariant?: boolean;
  isPublishedVersion?: boolean;
}

const Product: React.FC<ProductProps> = ({ isVariant, isPublishedVersion }) => {
  const { t, i18n } = useTranslation();
  const api = usePetCloudApi();
  const productsApi = api.productsApi();
  const productVersionsApi = api.productVersionsApi();
  const productGroupsApi = api.productGroupsApi();
  const productGroupMappingsApi = api.productGroupMappingsApi();
  const propertyGroupsApi = api.propertyGroupsApi();
  const params = useParams();
  const { getUrlParam } = useUrlParams();
  const errorHandler = useErrorHandler();
  const { toggleHelperPopup, renderHelperPopup } = useHelperPopup();
  const { authorizations } = useAuthorization();
  const storeHost = useStoreHost();
  const { isUsingCentralWarehouse, ready } = useManufacturerConditions();
  const link = useNavigate();
  const { removePersistedObjects, getPersistedObject } = useStorageData();
  const productId = params.productId;
  const urlParams = new URLSearchParams(window.location.search);
  const { excludeUnlockedOptions } = useUserTools();

  const [productVersionId, setProductVersionId] = useState(
    urlParams.get('versionId') ?? undefined
  );
  const [originalProduct, setOriginalProduct] =
    useState<ProductResponse | null>(null);
  const [product, setProduct] = useState<ProductResponse | null>(null);
  const [productVariants, setProductVariants] = useState<
    ProductVariantResponse[] | null
  >(null);
  const [productVersions, setProductVersions] = useState<
    ProductVersionResponse[] | null
  >(null);
  const [productGroups, setProductGroups] = useState<
    ProductGroupResponse[] | null
  >(null);
  const [productIsSubmitting, setProductIsSubmitting] = useState(false);
  const [readonlyMode, setReadonlyMode] = useState(false);
  const [performingCriticalActions, setPerformingCriticalActions] = useState<
    string[]
  >([]);
  const [versionDeactivationPopup, setVersionDeactivationPopup] =
    useState(false);
  const [productGroupMappings, setProductGroupMappings] = useState<
    ProductGroupMappingResponse[] | null
  >(null);
  const [errors, setErrors] = useState<Error[]>([]);
  const [cursor, setCursor] = useState<number | undefined>(undefined);
  const { additionalValidationRules, getAdditionalValidationRules } =
    useAdditionalPropertyValidationRules();
  const [availablePropertyGroups, setAvailablePropertyGroups] = useState<
    PropertyGroupResponse[] | null
  >(null);

  // tabs handling start
  const initialTabs = [
    {
      title: t('view.product.tabs.product'),
      key: 'product',
      progressSafe: true,
    },
    { title: t('view.product.tabs.media'), key: 'media', progressSafe: true },
    {
      title: t('view.product.tabs.pricing'),
      key: 'pricing',
      progressSafe: true,
    },
    { title: t('view.product.tabs.stock'), key: 'stock', progressSafe: true },
    {
      title: t('view.product.tabs.measures'),
      key: 'measures',
      progressSafe: true,
    },
    {
      title: t('view.product.tabs.settings'),
      key: 'settings',
      progressSafe: true,
    },
    {
      title: t('view.product.tabs.properties'),
      key: 'properties',
      progressSafe: true,
    },
  ];

  const [tabs, setTabs] = useState<TabConfig[]>(initialTabs);

  useEffect(() => {
    if (!isVariant && !tabs.find((tab) => tab.key === 'variants')) {
      const update = [...tabs];
      update.push({
        title: t('view.product.tabs.variants'),
        key: 'variants',
        progressSafe: true,
      });
      setTabs(update);
    }
  }, [tabs]);

  const updateProductViewTabs = (
    tabOverrides: { tabKey: string; safe: boolean; error?: boolean }[],
    tabArray?: TabConfig[] | undefined
  ) => {
    const update = tabArray ? [...tabArray] : [...tabs];
    tabOverrides.forEach((override) => {
      const i = update.findIndex((t) => t.key === override.tabKey);
      update[i] = {
        ...update[i],
        error: override.error ?? update[i].error,
        progressSafe: override.safe,
      };
    });
    setTabs(update);
  };

  // this checks if there are errors in each tab
  // while the individual components do their own error handling, this main component needs to be able to check them too
  useEffect(() => {
    checkErrorTabs();
  }, [errors]);

  const checkErrorTabs = () => {
    console.log(errors.length);
    const tabsWithErrors: string[] = [];
    hasError(
      errors,
      [
        'Ean',
        'Translations[0].Name',
        'Translations[1].Name',
        'Translations[0].Description',
        'Translations[1].Description',
        'Categories',
      ],
      () => tabsWithErrors.push('product')
    );
    hasError(errors, ['CoverId', 'ProductAssets'], () =>
      tabsWithErrors.push('media')
    );
    hasError(errors, ['ProductPrices'], () => tabsWithErrors.push('pricing'));
    hasError(errors, ['ProductStock'], () => tabsWithErrors.push('stock'));
    hasError(
      errors,
      [
        'Translations[0].PackUnit',
        'Translations[1].PackUnit',
        'Translations[0].PackUnitPlural',
        'Translations[1].PackUnitPlural',
      ],
      () => tabsWithErrors.push('measures')
    );
    return setMultipleErrorTabs(tabs, setTabs, tabsWithErrors);
  };
  // tabs handling end

  const queueCriticalAction = (action: string) => {
    const update = performingCriticalActions;
    update.push(action);
    setPerformingCriticalActions([...update]);
  };

  const killCriticalAction = (action: string) => {
    const update = performingCriticalActions;
    const i = update.indexOf(action);
    if (i !== -1) {
      update.splice(i, 1);
      setPerformingCriticalActions([...update]);
    }
  };

  // initialize all product data
  useEffect(() => {
    getMode();
    getProductVersions(cursor);
    getValidationErrorsCookie();
    getProductGroups();
  }, [productId]);

  const getProductGroups = () => {
    productGroupsApi
      .productGroupsGetProductGroups()
      .then((response) => {
        console.log(response);
        setProductGroups(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  // refresh data if productVersionId is updated
  useEffect(() => {
    getProduct();
    getProductVariants();
  }, [productVersionId]);

  const getValidationErrorsCookie = () => {
    const name = 'productValidationErrors_' + productId;

    //for some reason this has to be parsed twice (one inside getPersistedObject) and once here.
    const errs = JSON.parse(getPersistedObject(name));
    removePersistedObjects([name]);
    if (errs) {
      setErrors(errs);
    }
  };

  const getMoreProductVersions = () => {
    let c = cursor;
    let limit = productVersions?.length ?? 5;
    if (c !== undefined) {
      c -= 5;
      limit += 5;
      if (c >= 2) {
        getProductVersions(undefined, limit);
        setCursor(c);
      }
    }
  };

  const getProductVersions = (
    versionNumberCursor?: number | undefined,
    limit?: number
  ) => {
    if (productId) {
      productVersionsApi
        .productVersionsGetProductVersions(
          productId,
          limit ?? 5,
          versionNumberCursor
        )
        .then((response) => {
          console.log(response);
          setProductVersions(response.data);
          if (!cursor) {
            setCursor(
              response.data[0] ? response.data[0].versionNumber + 1 : undefined
            );
          }
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const getMode = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('readonly') === 'true' || isPublishedVersion) {
      setReadonlyMode(true);
    }
  };

  const update = (
    updatedProduct: ProductResponse,
    bypassTabSafetyCheck?: boolean
  ) => {
    if (
      JSON.stringify(updatedProduct) !== JSON.stringify(product) &&
      !bypassTabSafetyCheck
    ) {
      updateProductViewTabs([{ tabKey: 'product', safe: false }]);
    }
    setProduct({ ...updatedProduct });
  };

  // fetch product data
  const getProduct = () => {
    if (productId) {
      queueCriticalAction('gettingProduct');
      // for fetching of specific product versions, use the productsGetProductById endpoint
      if (productVersionId) {
        productsApi
          .productsGetProductById(productId, true, undefined, productVersionId)
          .then((response) => {
            console.log(response);
            getProductPost(response.data);
          })
          .catch((error) => {
            console.log(error);
            errorHandler.addError(error.response);
            killCriticalAction('gettingProduct');
          });
      } else {
        // in all other cases use the endpoint
        const idMethod = getUrlParam('idMethod');
        const productIdentifierType = (
          idMethod ? idMethod[0].toUpperCase() + idMethod.slice(1) : 'Id'
        ) as ProductIdentifierType;
        const idValue = getUrlParam('idValue') ?? productId;
        const manufacturerId = getUrlParam('manufacturerId') ?? undefined;
        productsApi
          .productsGetProductByIdentifier(
            productIdentifierType,
            idValue,
            manufacturerId,
            true,
            true
          )
          .then((response) => {
            console.log(response);
            getProductPost(response.data);
          })
          .catch((error) => {
            console.log(error);
            errorHandler.addError(error.response);
            killCriticalAction('gettingProduct');
          });
      }
    }
  };

  const getProductPost = (product: ProductResponse) => {
    setProduct(product);
    setOriginalProduct(product);
    killCriticalAction('gettingProduct');
    getProductGroupMappings(
      product.productGroupId,
      product.animalSpecies?.map((x) => x.id)
    );
    getAvailablePropertyGroups(product);
    const productGroupId = product.productGroupId;
    if (productGroupId) {
      getAdditionalValidationRules(productGroupId);
    }
  };

  const getAvailablePropertyGroups = (product: ProductResponse) => {
    propertyGroupsApi
      .propertyGroupsGetPropertyGroups(
        undefined,
        undefined,
        excludeUnlockedOptions(),
        undefined,
        product?.animalSpecies
          ? product.animalSpecies.map((x) => x.id)
          : undefined,
        product.productGroupId ? [product.productGroupId] : undefined
      )
      .then((response) => {
        console.log(response);
        setAvailablePropertyGroups(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getProductGroupMappings = (
    productGroupId: string | null | undefined,
    animalSpeciesIds: string[] | null | undefined
  ) => {
    if (productGroupId && animalSpeciesIds) {
      setProductGroupMappings(null);
      productGroupMappingsApi
        .productGroupMappingsGetProductGroupMappings(
          [productGroupId],
          animalSpeciesIds,
          excludeUnlockedOptions()
        )
        .then((response) => {
          console.log(response);
          setProductGroupMappings(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    } else {
      setProductGroupMappings([]);
    }
  };

  const getProductVariants = () => {
    if (productId) {
      productsApi
        .productsGetProductVariants(productId)
        .then((response) => {
          console.log(response);
          setProductVariants(
            response.data.map((x) => {
              const { state, ...rest } = x;
              return { state: state, ...rest };
            })
          );
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const updateCoverId = (coverId: string | undefined) => {
    if (product) {
      queueCriticalAction('updatingCoverId');
      productsApi
        .productsUpdateProduct(product.id, {
          ...product,
          coverId: coverId,
          animalSpeciesIds: product.animalSpecies.map((x) => x.id),
        } as UpdateProductRequest)
        .then((response) => {
          console.log(response.data);
          getProduct();
          Store.addNotification({
            message: t('view.product.notifications.updateCover_successful'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
          killCriticalAction('updatingCoverId');
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response, (errs) => {
            setErrors(errs);
          });
          killCriticalAction('updatingCoverId');
        });
    }
  };

  const updateTaxTypeIdentifier = (taxTypeIdentifier: TaxTypeIdentifier) => {
    if (product) {
      update({
        ...product,
        taxTypeIdentifier: taxTypeIdentifier,
      });
      submitUpdatedProduct(
        {
          ...product,
          taxTypeIdentifier: taxTypeIdentifier,
        },
        [],
        []
      );
    }
  };

  // checks if any product data has been changed
  const checkForChanges = () => {
    return originalProduct !== product;
  };

  const submitUpdatedProduct = (
    productObject: ProductResponse,
    errorsToBeReset: string[],
    tabErrorsToBeReset: string[]
  ) => {
    setProductIsSubmitting(true);
    queueCriticalAction('updatingProduct');
    const currentErrors = removeErrors(errors, setErrors, errorsToBeReset);
    productsApi
      .productsUpdateProduct(
        productObject.id,
        transformProductToRequest(productObject)
      )
      .then((response) => {
        console.log(response);
        Store.addNotification({
          message: t('view.product.notifications.update_successful'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
        setProductIsSubmitting(false);
        getProduct();
        updateProductViewTabs(
          [
            {
              tabKey: 'product',
              safe: true,
              error: tabErrorsToBeReset.includes('product') ? false : undefined,
            },
            {
              tabKey: 'measures',
              safe: true,
              error: tabErrorsToBeReset.includes('measures')
                ? false
                : undefined,
            },
            {
              tabKey: 'settings',
              safe: true,
              error: tabErrorsToBeReset.includes('settings')
                ? false
                : undefined,
            },
          ],
          tabs
        );
        killCriticalAction('updatingProduct');
        toggleHelperPopup();
      })
      .catch((error) => {
        console.log(error);
        setProductIsSubmitting(false);
        errorHandler.addError(error.response, (errs) => {
          addErrors(currentErrors, errs, setErrors);
        });
        killCriticalAction('updatingProduct');
      });
  };

  const deactivateVersion = () => {
    const versionId = productVersionId ?? product?.activeProductVersionId;
    if (versionId) {
      queueCriticalAction('deactivatingVersion');
      productVersionsApi
        .productVersionsDeactivateProductVersion(versionId)
        .then((response) => {
          console.log(response);
          setVersionDeactivationPopup(false);
          killCriticalAction('deactivatingVersion');
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          killCriticalAction('deactivatingVersion');
        });
    }
  };

  const getBadges = () => {
    const badges: {
      title: string;
      color: string;
    }[] = [];

    if (product) {
      badges.push({
        title: product.productNumber,
        color: 'var(--color-gray)',
      });
      if (product.parent) {
        // add variant options badge
        let variantOptionsString = '';
        const variantOptions = product?.variantOptions;
        if (variantOptions) {
          product?.variantOptions?.forEach((option, i) => {
            const title = option.name[i18n.language as TranslatedStringIndex];
            if (title) {
              variantOptionsString +=
                title + (i === variantOptions.length - 1 ? '' : ', ');
            }
          });
        }
        badges.push({
          title:
            variantOptionsString !== ''
              ? t('badges.variant') + ' | ' + variantOptionsString
              : t('badges.variant'),
          color: 'var(--color-inherited)',
        });
      }
      if (product.activeProductVersionId) {
        if (
          productVersions?.find((v) => v.id === product.activeProductVersionId)
            ?.syncedAt
        ) {
          badges.push({
            title: t('badges.active'),
            color: 'var(--color-success)',
          });
        } else {
          badges.push({
            title: t('view.product.versions.syncing'),
            color: 'var(--color-gray)',
          });
        }
      }
    }
    if (readonlyMode) {
      badges.push({
        title: t('badges.readonly'),
        color: 'var(--color-danger)',
      });
    }
    if (isPublishedVersion) {
      badges.push({
        title: t('badges.isPublishedVersion'),
        color: 'var(--color-gray)',
      });
    }
    return badges;
  };

  const openStorePageInNewTab = () => {
    if (product?.activeProductVersionId) {
      const unHyphenedProductId = productId?.replace(/-/g, '');
      const url = storeHost + '/detail/' + unHyphenedProductId;
      window.open(url, '_blank')?.focus();
    } else {
      Store.addNotification({
        message: t('view.product.notifications.cantView'),
        type: 'info',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 6000,
        },
      });
    }
  };

  const [warehouseUsage, setWarehouseUsage] = useState<{
    usingCentral: boolean;
    poOverride: boolean;
  } | null>(null);

  useEffect(() => {
    if (ready && product?.manufacturerId) {
      const result = isUsingCentralWarehouse(product.manufacturerId);
      setWarehouseUsage({
        usingCentral: result === true,
        poOverride: result === 'PO_OVERRIDE',
      });
    }
  }, [ready, product]);

  if (authorizations && warehouseUsage !== null) {
    return (
      <Has authorizations={['products:detail']}>
        {productId &&
        product &&
        productVersions &&
        productGroups &&
        productGroupMappings ? (
          <Stage>
            <Navigator
              titlePrefix={t('view.product.titlePrefix')}
              title={product.name[i18n.language as TranslatedStringIndex]}
              allowBackButton
              badges={getBadges()}
              isLoading={performingCriticalActions.length > 0}
              additionalElement={
                isVariant ? (
                  <Button
                    margin="right"
                    look={'secondary'}
                    type="icon"
                    action={() => link(`/products/${product?.parentId}`)}
                    helperCSSClass={'button-product-up'}
                  >
                    <IconArrowUp
                      fill="var(--color-text_primary)"
                      className="button-icon"
                    />
                  </Button>
                ) : undefined
              }
            >
              <Button
                type={'icon-text'}
                cta={t('view.product.view')}
                width={'minimal'}
                action={openStorePageInNewTab}
              >
                <Eye
                  className={'button-icon'}
                  fill={'var(--color-text_primary)'}
                />
              </Button>
              {isVariant ? (
                <Button
                  type={'icon'}
                  width={'minimal'}
                  look={'secondary'}
                  action={() => link(`/products/${product.parent.id}`)}
                >
                  <IconLinked
                    className={'button-icon'}
                    fill={'var(--color-text_primary)'}
                  />
                </Button>
              ) : null}
              {readonlyMode ? (
                <Button
                  cta={t('view.product.leaveReadonlyMode')}
                  action={() =>
                    (window.location.href =
                      (product.parent ? '/products/variant/' : '/products/') +
                      productId)
                  }
                  look={'secondary'}
                  width="minimal"
                />
              ) : null}
              {productVersions.find((v) => v.id === productVersionId)
                ?.isActive ? (
                <Button
                  type="icon-text"
                  cta={t('view.product.deactivateVersion')}
                  look="secondary-danger"
                  margin="left"
                  action={() => setVersionDeactivationPopup(true)}
                >
                  <Prohibit
                    className="button-icon button-icon-tiny"
                    fill="var(--color-danger)"
                  />
                </Button>
              ) : null}
            </Navigator>
            {renderHelperPopup(
              <InformationBox
                title={t('view.product.helpOverlays.savingProduct.title')}
                content={t('view.product.helpOverlays.savingProduct.text')}
                type={'info'}
                maxWidth={500}
              />,
              'helpOverlay_productSaving'
            )}
            <Popup
              width={'30%'}
              toggled={versionDeactivationPopup}
              close={() => setVersionDeactivationPopup(false)}
            >
              <div className="popup-title">
                {t('view.product.versionDeactivationPopup.title')}
              </div>
              <InformationBox
                content={t('view.product.versionDeactivationPopup.message')}
                type="warning"
                maxWidth={500}
              />
              <Button
                cta={t('view.product.versionDeactivationPopup.cta')}
                look="danger"
                width="full"
                action={deactivateVersion}
                isLoading={performingCriticalActions.length > 0}
              />
            </Popup>
            <StageColumns>
              <StageColumn size="two">
                <Tabs
                  key={tabs.toString()}
                  tabs={tabs}
                  updateProductViewTabs={updateProductViewTabs}
                  look="card"
                >
                  <Tab>
                    <HelperCard
                      text={t('view.product.helperCard.ready')}
                      knowledgeBaseItems={[
                        {
                          title: t(
                            'knowledgebase.product.tutorials.ready.title'
                          ),
                          text: t('knowledgebase.product.tutorials.ready.text'),
                          videoUri: t(
                            'knowledgebase.product.tutorials.ready.video'
                          ),
                        },
                      ]}
                      settingName={'product_ready'}
                    />
                    <Card bigScreenWidth="100%">
                      <CardSection title={t('view.product.meta.title')}>
                        <Meta
                          product={product}
                          updateProduct={(p) => {
                            update(p);
                          }}
                          errors={errors}
                          errorCallback={() =>
                            setErrorTab(tabs, setTabs, 'product')
                          }
                        />
                      </CardSection>
                      <CardSection
                        title={t('view.product.brandproductline.title')}
                      >
                        <BrandProductLine
                          product={product}
                          parentProduct={product.parent}
                          updateProduct={(p) => {
                            update(p);
                          }}
                          errors={errors}
                        />
                      </CardSection>
                      <CardSection title={t('view.product.information.title')}>
                        <Information
                          product={product}
                          brandName={product.brand?.name}
                          updateProduct={(p) => {
                            update(p, true);
                          }}
                          parentProduct={product.parent}
                          submitCurrentProduct={() => {
                            if (product) {
                              submitUpdatedProduct(
                                product,
                                [
                                  'Ean',
                                  'Translations[0].Name',
                                  'Translations[1].Name',
                                  'Name.TranslatedName.German.Name',
                                  'Name.TranslatedName.English.Name',
                                  'Translations[0].Description',
                                  'Translations[1].Description',
                                  'Categories',
                                ],
                                ['product']
                              );
                            }
                          }}
                          productIsSubmitting={productIsSubmitting}
                          readonly={readonlyMode}
                          buttonActive={originalProduct !== product}
                          errors={errors}
                          errorCallback={() => {
                            setErrorTab(tabs, setTabs, 'product');
                          }}
                        />
                      </CardSection>
                    </Card>
                    <Card bigScreenWidth="100%">
                      <CardSection
                        title={t('view.product.additionalinformation.title')}
                        inherited={
                          isVariant
                            ? !(
                                product.additionalInformation &&
                                product.additionalInformation.length > 0
                              )
                            : undefined
                        }
                        knowledgeBaseItems={[
                          {
                            title: t(
                              'knowledgebase.product.additionalInformation.table.title'
                            ),
                            text: t(
                              'knowledgebase.product.additionalInformation.table.text'
                            ),
                            mediaUri: t(
                              'knowledgebase.product.additionalInformation.table.media'
                            ),
                          },
                          {
                            title: t(
                              'knowledgebase.product.additionalInformation.richtext.title'
                            ),
                            text: t(
                              'knowledgebase.product.additionalInformation.richtext.text'
                            ),
                            mediaUri: t(
                              'knowledgebase.product.additionalInformation.richtext.media'
                            ),
                          },
                          {
                            title: t(
                              'knowledgebase.product.additionalInformation.media.title'
                            ),
                            text: t(
                              'knowledgebase.product.additionalInformation.media.text'
                            ),
                            mediaUri: t(
                              'knowledgebase.product.additionalInformation.media.media'
                            ),
                          },
                          {
                            title: t(
                              'knowledgebase.product.additionalInformation.downloads.title'
                            ),
                            text: t(
                              'knowledgebase.product.additionalInformation.downloads.text'
                            ),
                            mediaUri: t(
                              'knowledgebase.product.additionalInformation.downloads.media'
                            ),
                          },
                        ]}
                        validation={{
                          rules: additionalValidationRules,
                          identifier: 'additionalInformation',
                        }}
                      >
                        <ProductAdditionalInformation
                          productId={product.id}
                          additionalInformation={product.additionalInformation}
                          getProduct={getProduct}
                          readonly={readonlyMode}
                          queueCriticalAction={queueCriticalAction}
                          killCriticalAction={killCriticalAction}
                          inheritedAdditionalInformation={
                            product.parent?.additionalInformation
                          }
                          toggleProductSavingHelpOverlay={toggleHelperPopup}
                        />
                      </CardSection>
                    </Card>
                    <Card bigScreenWidth="100%">
                      <CardSection
                        title={t('view.product.analyticConstituents.title')}
                        inherited={
                          isVariant
                            ? !(
                                product.analyticConstituents &&
                                product.analyticConstituents.length > 0
                              )
                            : undefined
                        }
                        knowledgeBaseItems={[
                          {
                            title: t(
                              'knowledgebase.product.analyticConstituents.title'
                            ),
                            text: t(
                              'knowledgebase.product.analyticConstituents.text'
                            ),
                            mediaUri: t(
                              'knowledgebase.product.analyticConstituents.media'
                            ),
                          },
                        ]}
                        validation={{
                          rules: additionalValidationRules,
                          identifier: 'analytic_constituents_required',
                        }}
                      >
                        <Tabs
                          tabs={[
                            {
                              title: t(
                                'view.product.analyticConstituents.tabs.analyticConstituents'
                              ),
                              key: 'analyticConstituents',
                            },
                            {
                              title: t(
                                'view.product.analyticConstituents.tabs.kcal'
                              ),
                              key: 'kcal',
                            },
                          ]}
                          dontSetHash
                          noMargin
                        >
                          <Tab>
                            <AnalyticConstituents
                              productId={product.id}
                              analyticConstituents={
                                product.analyticConstituents
                              }
                              readonly={readonlyMode}
                              queueCriticalAction={queueCriticalAction}
                              killCriticalAction={killCriticalAction}
                              inheritedAnalyticConstituents={
                                product.parent?.analyticConstituents
                              }
                              updateProductViewTabs={updateProductViewTabs}
                              toggleProductSavingHelpOverlay={toggleHelperPopup}
                              updateProductAnalyticConstituents={(ac) =>
                                setProduct({
                                  ...product,
                                  analyticConstituents: ac,
                                })
                              }
                            />
                          </Tab>
                          <Tab>
                            <Kcal
                              productGroupMappings={productGroupMappings}
                              hasAnalyticConstituents={
                                !!product.analyticConstituents &&
                                product.analyticConstituents.length > 0
                              }
                              productId={productId}
                              propertyOptions={product.properties ?? []}
                              inheritedPropertyGroups={
                                product.parent?.propertyGroups
                              }
                            />
                          </Tab>
                        </Tabs>
                      </CardSection>
                    </Card>
                  </Tab>
                  <Tab>
                    <Has
                      authorizations={['product_assets:list']}
                      showNoAuthorization
                    >
                      <Card bigScreenWidth="100%">
                        <CardSection>
                          <Media
                            productId={product.id}
                            productVersionId={productVersionId}
                            coverId={product.coverId}
                            updateCoverId={updateCoverId}
                            inheritedContent={product.parent?.productAssets}
                            allowGallery={true}
                            readonly={readonlyMode}
                            queueCriticalAction={queueCriticalAction}
                            killCriticalAction={killCriticalAction}
                            errors={errors}
                            errorCallback={() => {
                              setErrorTab(tabs, setTabs, 'media', true);
                            }}
                            removeErrors={(keys: string[]) =>
                              removeErrors(errors, setErrors, keys)
                            }
                            updateProductViewTabs={updateProductViewTabs}
                            toggleProductSavingHelpOverlay={toggleHelperPopup}
                          />
                        </CardSection>
                      </Card>
                    </Has>
                  </Tab>
                  <Tab>
                    <Has
                      authorizations={[
                        'product_prices:list',
                        'currency:list',
                        'countries:list',
                      ]}
                      showNoAuthorization
                    >
                      <Card bigScreenWidth="100%">
                        <CardSection>
                          <Pricing
                            productId={product.id}
                            productVersionId={productVersionId}
                            taxTypeIdentifier={product.taxTypeIdentifier}
                            updateTaxTypeIdentifier={updateTaxTypeIdentifier}
                            inheritedProductPrices={
                              product.parent?.productPrices
                            }
                            readonly={readonlyMode}
                            errors={errors}
                            removeErrors={(keys: string[]) =>
                              removeErrors(errors, setErrors, keys)
                            }
                            updateProductViewTabs={updateProductViewTabs}
                            toggleProductSavingHelpOverlay={toggleHelperPopup}
                          />
                        </CardSection>
                      </Card>
                    </Has>
                  </Tab>
                  <Tab>
                    <Has
                      authorizations={[
                        'product_stocks:list',
                        'warehouses:list',
                      ]}
                      showNoAuthorization
                    >
                      <Card bigScreenWidth="100%">
                        <CardSection title={t('view.product.stocks.title')}>
                          <Stocks
                            productId={product.id}
                            readonly={readonlyMode}
                            errors={errors}
                            updateProductViewTabs={updateProductViewTabs}
                          />
                        </CardSection>
                      </Card>
                    </Has>
                    <Has
                      authorizations={['delivery_times:list']}
                      showNoAuthorization
                    >
                      <Card bigScreenWidth="100%">
                        <CardSection>
                          <Shipping
                            productId={product.id}
                            productVersionId={productVersionId}
                            readonly={readonlyMode}
                          />
                        </CardSection>
                      </Card>
                    </Has>
                  </Tab>
                  <Tab>
                    <Has
                      authorizations={['product_units:list']}
                      showNoAuthorization
                    >
                      <Card bigScreenWidth="100%">
                        <CardSection title={t('view.product.measures.title')}>
                          <Measures
                            product={product}
                            updateProduct={(p) => {
                              update(p);
                            }}
                            submitCurrentProduct={() => {
                              if (product) {
                                submitUpdatedProduct(
                                  product,
                                  [
                                    'Translations[0].PackUnit',
                                    'Translations[1].PackUnit',
                                    'Translations[0].PackUnitPlural',
                                    'Translations[1].PackUnitPlural',
                                  ],
                                  ['measures']
                                );
                              }
                            }}
                            buttonActive={checkForChanges()}
                            productIsSubmitting={productIsSubmitting}
                            readonly={readonlyMode}
                            errors={errors}
                          />
                        </CardSection>
                      </Card>
                    </Has>
                  </Tab>
                  <Tab>
                    <Card bigScreenWidth="100%">
                      <CardSection
                        title={t('view.product.stocksettings.title')}
                      >
                        <StockSettings
                          restockTimeDays={product.restockTimeDays}
                          inheritedRestockTimeDays={
                            product.parent?.restockTimeDays
                          }
                          updateRestockTimeDays={(e) => {
                            update({
                              ...product,
                              restockTimeDays: parseInt(e),
                            });
                          }}
                          isCloseout={!!product.isCloseout}
                          inheritedIsCloseout={product.parent?.isCloseout}
                          toggleIsCloseout={() => {
                            update({
                              ...product,
                              isCloseout: !product.isCloseout,
                            });
                          }}
                        />
                      </CardSection>
                      <CardSection title={t('view.product.cartoptions.title')}>
                        <CartOptions
                          product={product}
                          updateProduct={(p) => {
                            update(p);
                          }}
                        />
                      </CardSection>
                      {warehouseUsage.usingCentral ||
                      warehouseUsage.poOverride ? (
                        <CardSection
                          title={t('view.product.regulations.title')}
                          productOwnerOverride={warehouseUsage.poOverride}
                        >
                          <Regulations
                            isBatchControlled={product.isBatchControlled}
                            inheritedIsBatchControlled={
                              product.parent?.isBatchControlled
                            }
                            toggleIsBatchControlled={() => {
                              update({
                                ...product,
                                isBatchControlled: !product.isBatchControlled,
                              });
                            }}
                            isBestBeforeControlled={
                              product.isBestBeforeControlled
                            }
                            inheritedIsBestBeforeControlled={
                              product.parent?.isBestBeforeControlled
                            }
                            toggleIsBestBeforeControlled={() => {
                              update({
                                ...product,
                                isBestBeforeControlled:
                                  !product.isBestBeforeControlled,
                              });
                            }}
                            isDangerousGoods={product.isDangerousGoods}
                            inheritedIsDangerousGoods={
                              product.parent?.isDangerousGoods
                            }
                            toggleIsDangerousGoods={() => {
                              update({
                                ...product,
                                isDangerousGoods: !product.isDangerousGoods,
                              });
                            }}
                          />
                        </CardSection>
                      ) : null}
                      <CardSection noTopPadding={true}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 20,
                          }}
                        >
                          {!readonlyMode ? (
                            <Button
                              cta={t('actions.save')}
                              action={() => {
                                if (product) {
                                  submitUpdatedProduct(
                                    product,
                                    [],
                                    ['settings']
                                  );
                                }
                              }}
                              look="save"
                              width="minimal"
                              active={readonlyMode ? false : checkForChanges()}
                              isLoading={productIsSubmitting}
                            />
                          ) : null}
                        </div>
                      </CardSection>
                    </Card>
                  </Tab>
                  <Tab>
                    {availablePropertyGroups ? (
                      <Has
                        authorizations={['property_groups:list']}
                        showNoAuthorization
                      >
                        {isVariant ? (
                          <Card
                            bigScreenWidth="100%"
                            title={t('view.product.variantOptions.title')}
                          >
                            <CardSection>
                              <VariantOptions
                                options={product.variantOptions ?? []}
                                availablePropertyGroups={
                                  availablePropertyGroups
                                }
                              />
                            </CardSection>
                          </Card>
                        ) : null}
                        <Card bigScreenWidth="100%">
                          <CardSection>
                            <Properties
                              productId={product.id}
                              productVersionId={productVersionId}
                              inheritedProductProperties={
                                product.parent?.propertyGroups
                              }
                              readonly={readonlyMode}
                              toggleProductSavingHelpOverlay={toggleHelperPopup}
                              productGroupMappings={productGroupMappings}
                              selectedAnimalSpecies={product.animalSpecies}
                              validationOptional={
                                !!(
                                  product.children &&
                                  product.children.length > 0
                                )
                              }
                              availableProductProperties={
                                availablePropertyGroups
                              }
                            />
                          </CardSection>
                        </Card>
                      </Has>
                    ) : (
                      <LoadingContainer />
                    )}
                  </Tab>
                  <Tab>
                    <Has
                      authorizations={['property_groups:list']}
                      showNoAuthorization
                    >
                      {product.parent === null ? (
                        <Card bigScreenWidth="100%">
                          <CardSection title={t('view.product.variants.title')}>
                            <Variants
                              productVariants={productVariants}
                              refreshProductVariants={getProductVariants}
                              productId={product.id}
                              readonly={readonlyMode}
                              queueCriticalAction={queueCriticalAction}
                              killCriticalAction={killCriticalAction}
                              productGroupId={product.productGroupId}
                              animalSpeciesIds={product.animalSpecies?.map(
                                (x) => x.id
                              )}
                              productGroupMappings={productGroupMappings}
                            />
                          </CardSection>
                        </Card>
                      ) : null}
                    </Has>
                  </Tab>
                </Tabs>
              </StageColumn>
              <StageColumn size="one">
                <Card bigScreenWidth="100%">
                  <CardSection
                    title={t('view.product.publishing.title')}
                    hint={{
                      message: t('view.product.publishing.hint'),
                      stretchToLeft: true,
                    }}
                    knowledgeBaseItems={[
                      {
                        title: t(
                          'knowledgebase.product.tutorials.activation.title'
                        ),
                        text: t(
                          'knowledgebase.product.tutorials.activation.text'
                        ),
                        videoUri: t(
                          'knowledgebase.product.tutorials.activation.video'
                        ),
                      },
                    ]}
                  >
                    <StateManagement
                      product={product}
                      refreshProduct={getProduct}
                      refreshVariants={getProductVariants}
                      refreshProductVersions={() => getProductVersions(cursor)}
                      variants={productVariants}
                      readonly={readonlyMode}
                      isPerformingCriticalAction={
                        performingCriticalActions.length > 0
                      }
                      queueCriticalAction={queueCriticalAction}
                      killCriticalAction={killCriticalAction}
                      setErrors={setErrors}
                      isVariant={isVariant}
                    />
                  </CardSection>
                  <CardSection
                    title={t('view.product.versions.title')}
                    hint={{
                      message: t('view.product.versions.hint'),
                      stretchToLeft: true,
                    }}
                  >
                    <Versions
                      key={productVersionId}
                      productVersions={productVersions}
                      getMoreVersions={getMoreProductVersions}
                      productId={productId}
                      visitedProductVersionId={productVersionId}
                      setProductVersionId={setProductVersionId}
                      deactivateVersion={() =>
                        setVersionDeactivationPopup(true)
                      }
                    />
                  </CardSection>
                </Card>
                <Card bigScreenWidth="100%">
                  <CardSection>
                    <AnimalSpeciesAndProductGroup
                      product={product}
                      updateProduct={update}
                      onFinish={() => {
                        if (product) {
                          submitUpdatedProduct(product, [], []);
                        }
                      }}
                      locked={!!isVariant}
                      readonly={readonlyMode}
                      errors={errors}
                    />
                  </CardSection>
                  <CardSection>
                    <Categories
                      productId={product.id}
                      productVersionId={productVersionId}
                      inheritedProductCategories={product.parent?.categories}
                      readonly={readonlyMode}
                      errors={errors}
                      productGroupId={product.productGroupId}
                      animalSpeciesIds={product.animalSpecies.map((x) => x.id)}
                      mainCategoryId={product.mainCategoryId}
                      setMainCategoryId={(categoryId) =>
                        update({
                          ...product,
                          mainCategoryId: categoryId,
                        })
                      }
                      onSubmitProduct={() => {
                        if (product) {
                          submitUpdatedProduct(product, [], []);
                        }
                      }}
                    />
                  </CardSection>
                </Card>
                <Card bigScreenWidth={'100%'} title={t('view.product.errors')}>
                  <CardSection>
                    <ErrorsOverview errors={product.errors} showMessageInHead />
                  </CardSection>
                </Card>
              </StageColumn>
            </StageColumns>
          </Stage>
        ) : (
          <LoadingContainer />
        )}
      </Has>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Product;

export const transformProductToRequest = (response: ProductResponse) => {
  return {
    ...response,
    animalSpeciesIds: response.animalSpecies.map((x) => x.id),
    taxTypeIdentifier: response.taxTypeIdentifier ?? TaxTypeIdentifier.Full,
    brandId: response.brandId ?? 'error',
  };
};
